import { takeLatest, call, put, all, delay } from "redux-saga/effects";
import axios from "axios";
import {API_URL_BASE, SPELLING_API_URL_BASE} from "../constants/config";
import * as actionType from "../constants/actionTypes";
import {handleApiError, handleApiSuccess} from "../utils/common";
import {store} from "../store";

// A variable to determine if environment is Development and if data will be loaded from local json file
const isDevelopmentMode = (process.env.NODE_ENV === 'development' && true);

// Helper function
function getSearchText(type) {
  const {searchText,didUMean,didUMeanNews,relatedSearchText} = store.getState().search;
  let didYouMean = '';
  let searchTextFormatted = '';

  if (type === 'all'){
    didYouMean = didUMean;
  } else if (type === 'news'){
    didYouMean = didUMeanNews;
  }

  if (didYouMean !== '' && relatedSearchText !== '') {
    searchTextFormatted = (didYouMean + ' ' + relatedSearchText).trim();
  }
  else {
    searchTextFormatted = (searchText + ' ' + relatedSearchText).trim();
  }

  return searchTextFormatted;
}

// API calls
function callAutocompleteApi(text) {
  if (isDevelopmentMode){
    return axios.get('/fake-data/autocomplete.json');
  }

  const apiUrl = `${SPELLING_API_URL_BASE}/autocomplete?Text=${text.trim()}`;
  return axios.get(apiUrl);
}

function callSearchApi(pageNumber) {
  if (isDevelopmentMode){
    return axios.get('/fake-data/web-search.json');
  }

  const {pageSize,searchTextHasQuote,relatedSearchText} = store.getState().search;
  const localStorageData = localStorage.getItem('selectedSearchEngine');

  let selectedSearchEngine = '0';
  let searchTextFormatted = getSearchText('all');
  let autoCorrect = true;
  let apiUrl = '';

  if (localStorageData){
    selectedSearchEngine = localStorageData;
  }

  if (relatedSearchText !== '') {
    autoCorrect = false;
  }

  if (searchTextHasQuote === true) {
    apiUrl = `${API_URL_BASE}/GetWebSearch?q=%22${searchTextFormatted}%22&pageNumber=${pageNumber}&pageSize=${pageSize}&autoCorrect=${autoCorrect}&searchEngineId=${selectedSearchEngine}`;
  }
  else {
    apiUrl = `${API_URL_BASE}/GetWebSearch?q=${searchTextFormatted}&pageNumber=${pageNumber}&pageSize=${pageSize}&autoCorrect=${autoCorrect}&searchEngineId=${selectedSearchEngine}`;
  }

  return axios.get(apiUrl);
}

function callNewsSearchApi(pageNumber) {
  if (isDevelopmentMode){
    return axios.get('/fake-data/news-search.json');
  }

  const {pageSize,relatedSearchText} = store.getState().search;
  const localStorageData = localStorage.getItem('selectedSearchEngine');

  let selectedSearchEngine = '0';
  let searchTextFormatted = getSearchText('news');
  let autoCorrect = true;

  if (localStorageData){
    selectedSearchEngine = localStorageData;
  }

  if (relatedSearchText !== '') {
    autoCorrect = false;
  }

  const apiUrl = `${API_URL_BASE}/GetNewsSearch?q=${searchTextFormatted}&pageNumber=${pageNumber}&pageSize=${pageSize}&autoCorrect=${autoCorrect}&searchEngineId=${selectedSearchEngine}`;
  return axios.get(apiUrl);
}

function callImageSearchApi(pageNumber) {
  if (isDevelopmentMode){
    if (pageNumber < 3){
      return axios.get(`/fake-data/image-search-${pageNumber}.json`);
    } else{
      return axios.get(`/fake-data/image-search-3.json`);
    }
  }

  const {searchText,pageSizeImage,pageSizeImageInitial} = store.getState().search;
  const localStorageData = localStorage.getItem('selectedSearchEngine');

  let selectedSearchEngine = '0';
  let pageSize = pageSizeImage;

  if (localStorageData){
    selectedSearchEngine = localStorageData;
  }

  if (pageNumber === 1){
    pageSize = pageSizeImageInitial;
  }

  const apiUrl = `${API_URL_BASE}/GetImageSearch?q=${searchText.trim()}&pageNumber=${pageNumber}&pageSize=${pageSize}&autoCorrect=false&searchEngineId=${selectedSearchEngine}`;
  return axios.get(apiUrl);
}

// Generator functions: makes the api call when Search saga sees the action
function* autoComplete(action) {
  try {
    yield delay(300);
    yield put({type: actionType.AUTO_COMPLETE_WAITING, value: true});
    const response = yield call(callAutocompleteApi, action.value);

    yield handleApiSuccess(response,actionType.AUTO_COMPLETE_WAITING,actionType.AUTO_COMPLETE_SUCCESS)
  } catch (error) {
    yield handleApiError(error,actionType.AUTO_COMPLETE_WAITING,actionType.AUTO_COMPLETE_FAILED)
  }
}

function* webSearch(action) {
  const {relatedSearchText} = store.getState().search;

  try {
    yield put({type: actionType.SEARCH_WAITING_REQUEST, value: true});
    yield put({type: actionType.CLEAR_SEARCH_DATA});

    const response = yield call(callSearchApi, action.value.pageNumber);
    yield handleApiSuccess({...response.data, searchedText:getSearchText('all'), currentPage:action.value.pageNumber},actionType.SEARCH_WAITING_REQUEST,actionType.SEARCH_SUCCESS);

    if (relatedSearchText !== '') {
      yield put({type: actionType.SET_RELATED_SEARCH_TO_SEARCH_TEXT, value: relatedSearchText});
    }
  } catch (error) {
    yield handleApiError(error,actionType.SEARCH_WAITING_REQUEST,actionType.SEARCH_FAILED)
  }
}

function* newsSearch(action) {
  const {relatedSearchText} = store.getState().search;

  try {
    yield put({type: actionType.NEWS_WAITING_REQUEST, value: true});
    yield put({type: actionType.CLEAR_NEWS_DATA});

    const response = yield call(callNewsSearchApi, action.value.pageNumber);
    yield handleApiSuccess({...response.data, searchedText:getSearchText('news'), currentPage:action.value.pageNumber},actionType.NEWS_WAITING_REQUEST,actionType.NEWS_SUCCESS);

    if (relatedSearchText !== '') {
      yield put({type: actionType.SET_RELATED_SEARCH_TO_SEARCH_TEXT, value: relatedSearchText});
    }
  } catch (error) {
    yield handleApiError(error,actionType.NEWS_WAITING_REQUEST,actionType.NEWS_SUCCESS)
  }
}

function* imageSearch(action) {
  const {searchText} = store.getState().search;

  try {
    yield put({type: actionType.IMAGES_WAITING_REQUEST, value: true});
    yield put({type: actionType.CLEAR_DID_U_MEAN});

    const response = yield call(callImageSearchApi, action.value.pageNumber);
    if (isDevelopmentMode){
      // Delay xxxx ms to load local json data to simulate like api call
      yield delay(2000);
    }

    if (response.status === 204){
      yield handleApiError({response:{status: response.status, data:response.statusText}},actionType.IMAGES_WAITING_REQUEST,actionType.IMAGES_FAILED);
    } else{
      yield handleApiSuccess({...response.data, searchedText:searchText, currentPage:action.value.pageNumber},actionType.IMAGES_WAITING_REQUEST, actionType.IMAGES_SUCCESS);
    }
  } catch (error) {
    yield handleApiError(error,actionType.IMAGES_WAITING_REQUEST,actionType.IMAGES_FAILED);
  }
}

// Search saga: watches for search actions dispatched to the store
export function* searchSaga() {
  yield all([
    yield takeLatest(actionType.AUTO_COMPLETE_CALL, autoComplete),
    yield takeLatest(actionType.WEB_SEARCH_CALL, webSearch),
    yield takeLatest(actionType.NEWS_SEARCH_CALL, newsSearch),
    yield takeLatest(actionType.IMAGE_SEARCH_CALL, imageSearch)
  ]);
}
