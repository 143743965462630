import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import {Modal} from "react-bootstrap";
import ImageGallery from "react-image-gallery";

import SearchInput from "../SearchInput";
import closeIcon from '../../../assets/images/img-zm-close.svg';
import {capitalizeFirstLetter} from "../../../libs/common";
// import zoomInIcon from '../../../assets/images/img-zm-in.svg';
// import zoomOutIcon from '../../../assets/images/img-zm-out.svg';
import zoomPrevIcon from '../../../assets/images/img-zm-prev.svg';
import zoomNextIcon from '../../../assets/images/img-zm-next.svg';

function ImageDetail(props) {
  const [images, setImages] = useState([]);
  const [selectedItem,setSelectedItem] = useState(null);
  const [startIndex, setStartIndex] = useState(0);
  // const [] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const formattedImages = props.searchItemsImage.map((image) => {
      return {
        original: image.imageUrl,
        thumbnail: image.imageBase64,
      }
    });

    const selectedImageIndex = props.searchItemsImage.findIndex((image) => {
      return image.imageUrl === atob(location.hash.substr(1));
    });

    const selectedImage = props.searchItemsImage.find((item)=>{
      return item.imageUrl === atob(location.hash.substr(1));
    });

    setImages(formattedImages);
    setSelectedItem(selectedImage);
    setStartIndex(selectedImageIndex);
  }, [props.searchItemsImage]);

  const handleClose = (event) => {
    event.preventDefault();
    props.handleClose();
  }

  const onSlide = (currentIndex) =>{
    setSelectedItem(props.searchItemsImage[currentIndex]);
    setStartIndex(currentIndex);
  }

  const handleImageError = (event)=> {
    const { onErrorImageURL } = props;
    if (onErrorImageURL && event.target.src.indexOf(onErrorImageURL) === -1) {
      /* eslint-disable no-param-reassign */
      event.target.src = onErrorImageURL;
      /* eslint-enable no-param-reassign */
    }
  }

  const renderItem = ()=>{
    const selectedImage = props.searchItemsImage[startIndex];
    const url = selectedImage.imageUrl;

    return(
      <div style={{display:"flex"}}>
        <a href={url} target="_blank">
          <img className="image-gallery-image" src={url} alt=""/>
        </a>
      </div>
    )
  }

  const renderLeftNav = (onClick, disabled) => {
    if (disabled){
      return null;
    }

    return (
      <a href="" onClick={(event) => {event.preventDefault(); onClick()}} className="image-gallery-icon image-gallery-left-nav">
        <img src={zoomPrevIcon} className='image-gallery-svg' alt=""/>
      </a>
    )
  }

  const renderRightNav = (onClick, disabled) => {
    if (disabled){
      return null;
    }

    return (
      <a href="" onClick={(event) => {event.preventDefault(); onClick()}} className="image-gallery-icon image-gallery-right-nav">
        <img src={zoomNextIcon} className='image-gallery-svg' alt=""/>
      </a>
    )
  }

  const renderThumbInner = (item)=> {
    const { onThumbnailError } = props;
    const handleThumbnailError = onThumbnailError || handleImageError;

    return (
      <div className="image-gallery-thumbnail-inner">
        <img
          className="image-gallery-thumbnail-image"
          src={item.thumbnail}
          alt={item.thumbnailAlt}
          title={item.thumbnailTitle}
          onError={handleThumbnailError}
        />
        {
          item.thumbnailLabel && (
            <div className="image-gallery-thumbnail-label">
              {item.thumbnailLabel}
            </div>
          )
        }
      </div>
    );
  }

  return (
    <Modal show={true} onHide={handleClose} backdrop="static" keyboard={false} dialogClassName="image-detail-modal">
      <Modal.Header>
        <div className={"cw-container image-zoom browser"}>
          <SearchInput isSettingsPage={false}/>
        </div>
        <a href="/" onClick={(event)=>handleClose(event)}>
          <img src={closeIcon} className="close-icon" alt=""/>
        </a>
      </Modal.Header>
      <Modal.Body>
        <div style={{display:"flex"}}>
          <ImageGallery
            items={images}
            startIndex={startIndex}
            infinite={false}
            useTranslate3D={false}
            slideDuration={0}
            showPlayButton={false}
            showFullscreenButton={false}
            onSlide={onSlide}
            renderItem={renderItem}
            renderLeftNav={renderLeftNav}
            renderRightNav={renderRightNav}
            renderThumbInner={renderThumbInner}
            // useBrowserFullscreen={false}
            // renderFullscreenButton ={(onClick, isFullscreen) => {
            //   const onClickZoomIcon = (event)=>{
            //     event.preventDefault();
            //     onClick();
            //   }
            //
            //   return (
            //     <a href="/" className={`image-gallery-fullscreen-button${isFullscreen ? ' active' : ''}`} onClick={(event)=>onClickZoomIcon(event)}>
            //       {isFullscreen?
            //         <img src={zoomOutIcon} className="zmin-icon" alt=""/>
            //         :
            //         <img src={zoomInIcon} className="zmin-icon" alt=""/>
            //       }
            //     </a>
            //   );
            // }}
          />
          <div className="description" style={{color:'#fff'}}>
            <div className="card">
              <a className="title" href={selectedItem && selectedItem.webpageUrl} target="_blank">{selectedItem && selectedItem.imageTitle}</a>
              <div style={{display:"flex", alignItems:"center",marginTop:"4px"}}>
                <a className="domain" href={selectedItem && selectedItem.webpageUrl} target="_blank">
                  {selectedItem && selectedItem.favIconBase64 &&
                    <img className="favicon" src={selectedItem.favIconBase64} alt=""/>
                  }
                  {selectedItem && capitalizeFirstLetter(selectedItem.domainName)}
                </a>
                <span className="divider">|</span>
                <a className="pixel" href="" onClick={(event)=>event.preventDefault()}>{selectedItem && selectedItem.imageOriginalWidth}x{selectedItem && selectedItem.imageOriginalHeight}</a>
              </div>
            </div>
          </div>
        </div>

      </Modal.Body>
    </Modal>
  )
}

export default ImageDetail;
