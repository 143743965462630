import React from 'react';
import {connect} from "react-redux";
import MultiClamp from '../../libs/react-multi-clamp';
import SearchItemImage from "../Common/SearchItemImage";
import CustomTimeAgo from "../Common/CustomTimeAgo";


function NewsItem(props) {
  const {item,windowInnerWidth} = props;
  const isPublishTimeAgoActive=true;

  const truncatedDomain = ()=> {
    let truncateVal;

    if (item.imageBase64 === '' && item.imageUrl === '') {
      truncateVal = parseInt((windowInnerWidth * 0.048).toString());
    }
    else {
      truncateVal = parseInt((windowInnerWidth * 0.038).toString());
    }

    return item.domainName.length > truncateVal ? item.domainName.substring(0, truncateVal) + "..." : item.domainName;
  };

  return(
    <React.Fragment>
      <div className="container-fluid d-none d-sm-block news-item-row" style={{paddingLeft:'2px',paddingRight:'2px'}}>
        <div className="row mb-4" style={{marginRight:'20px'}}>
          <div className="col-sm-18 pr-0">
            {item.imageBase64 !== '' &&
              <a href={item.imageUrl}>
                <SearchItemImage imgSrc={item.imageBase64} classNames="search-image"/>
              </a>
            }
          </div>
          <div className={(item.imageBase64 ==='' && item.imageUrl ===''?'col-sm-100 pl-3 ':'') + (item.imageBase64 !=='' || item.imageUrl !==''?'col-sm-82 pl-2 ':'')}>
            <div className="row" style={{marginTop:'6px',lineHeight:'16px'}}>
              <div className="col-100 title-column">
                <MultiClamp ellipsis="..." clamp={1}>
                  <a href={item.url} className="search-title">
                    <span dangerouslySetInnerHTML={{__html: item.title}}/>
                  </a>
                </MultiClamp>
              </div>
            </div>
            <div className="row" style={{lineHeight:'17px'}}>
              <div className="col-100 publishtime-column">
                <a className="search-uri" href='/' onClick={(e)=>e.preventDefault()}>
                  {item.domainName }
                </a>
                {item.publishedTime && isPublishTimeAgoActive &&
                  <span style={{color:'#808080',fontSize:'13px',wordSpacing:'-.5px',letterSpacing:'-.1px'}}>
                    &nbsp;
                    -
                    &nbsp;
                    <CustomTimeAgo date={item.publishedTime} includePreFix={false} includePostFix={false}/>
                  </span>
                }
              </div>
            </div>
            <div className="row" style={{marginTop:'1px'}}>
              <div className="col-100 search-description" style={{lineHeight:'15px'}}>
                <MultiClamp ellipsis="..." clamp={4}>
                  <span dangerouslySetInnerHTML={{__html: item.description}}/>
                </MultiClamp>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid d-block d-sm-none p-2 mb-2 mobile-content">
        <div className="row" style={{marginTop:'2px'}}>
          <div className="col-100">
            <a href={item.url} className="search-title">
              <MultiClamp ellipsis="..." clamp={2}>
                <span dangerouslySetInnerHTML={{__html: item.title}}/>
              </MultiClamp>
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-100">
            <a href={item.url} className="search-uri">
              {truncatedDomain()}
            </a>
            {item.publishedTime && isPublishTimeAgoActive &&
              <span style={{color:'#808080',fontSize:'15px'}}>
                &nbsp;
                &#9679;
                &nbsp;
                <CustomTimeAgo date={item.publishedTime} includePreFix={false} includePostFix={false}/>
              </span>
            }
          </div>
        </div>
        <hr className="content-divider"/>
        <div className="row">
          <div className="col-30 pr-0">
            {item.imageBase64 !== '' &&
              <SearchItemImage imgSrc={item.imageBase64} classNames="search-image mt-1"/>
            }
          </div>
          <div className={(item.imageBase64 === '' && item.imageUrl === ''?'col-100 ':'') + (item.imageBase64 !=='' || item.imageUrl !=='' ?'col-70':'')}>
            <MultiClamp ellipsis="..." clamp={5}>
              <div dangerouslySetInnerHTML={{__html: item.description}}/>
            </MultiClamp>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const stateToProps=(state)=>{
  return{
    windowInnerWidth:state.common.windowInnerWidth,
  }
};

export default connect(stateToProps,null)(NewsItem);
