import {
  PUBLIC_SEARCH_ENGINE_LIST_FAILED,
  PUBLIC_SEARCH_ENGINE_LIST_SUCCESS,
  PUBLIC_SEARCH_ENGINE_LIST_WAITING,
  SET_SELECTED_SEARCH_ENGINE
} from "../constants/actionTypes";
import {primaryColor} from "../constants/defaultValues";

const initialState = {
  waitingSearchEngines:false,
  searchEnginesSuccess:false,
  searchEnginesError:null,
  searchEngines : [],
  selectedSearchEngine: null,
  theme:{
    id:"0",
    primaryColor: primaryColor
  }
};

export default (state = initialState,action)=>{
  switch (action.type) {
    case PUBLIC_SEARCH_ENGINE_LIST_WAITING:{
      return {...state,waitingSearchEngines:action.value};
    }
    case PUBLIC_SEARCH_ENGINE_LIST_SUCCESS:{
      return {
        ...state,
        searchEngines: action.value,
        searchEnginesSuccess: true,
        searchEnginesError: null
      };
    }
    case PUBLIC_SEARCH_ENGINE_LIST_FAILED:{
      return {
        ...state,
        searchEnginesSuccess: false,
        searchEnginesError: action.value
      }
    }
    case SET_SELECTED_SEARCH_ENGINE:{
      const selectedSearchEngine = action.value;
      let themeColor = selectedSearchEngine.themeColor;

      if (selectedSearchEngine.hash === "0"){
        themeColor = primaryColor;
      }

      return {
        ...state,
        selectedSearchEngine: action.value,
        theme:{
          id: selectedSearchEngine.hash,
          primaryColor: themeColor
        }
      }
    }
    default:
      return state;
  }
}
