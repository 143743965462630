import axios from 'axios';
import {store} from '../store'

import {API_URL_BASE, SPELLING_API_URL_BASE} from '../constants/config';
import * as actionType from '../constants/actionTypes';
import {handleServerSuccess,handleServerError} from "../utils/common";

export const callSearchApi =(pageNumber)=>{
  const {searchText,pageSize,searchTextHasQuote,didUMean,relatedSearchText} = store.getState().search;
  let searchTextFormatted = '';
  let autoCorrect = true;
  let apiUrl = '';

  if (relatedSearchText !== '') {
    autoCorrect = false;
  }

  if (didUMean !== '' && relatedSearchText !== '') {
    searchTextFormatted = (didUMean + ' ' + relatedSearchText).trim();
  }
  else {
    searchTextFormatted = (searchText + ' ' + relatedSearchText).trim();
  }

  if (searchTextHasQuote === true) {
    apiUrl = `${API_URL_BASE}/GetWebSearch?q=%22${searchTextFormatted}%22&pageNumber=${pageNumber}&pageSize=${pageSize}&autoCorrect=${autoCorrect}`;
  }
  else {
    apiUrl = `${API_URL_BASE}/GetWebSearch?q=${searchTextFormatted}&pageNumber=${pageNumber}&pageSize=${pageSize}&autoCorrect=${autoCorrect}`;
  }

  return (dispatch)=>{
    dispatch({type: actionType.SEARCH_WAITING_REQUEST, value:true});
    dispatch({type: actionType.CLEAR_SEARCH_DATA});

    if (relatedSearchText !== '') {
      dispatch({type:actionType.SET_RELATED_SEARCH_TO_SEARCH_TEXT,value: relatedSearchText});
    }

    axios.get(apiUrl)
      .then((response)=>{
        handleServerSuccess({...response.data, searchedText:searchTextFormatted, currentPage:pageNumber},dispatch, actionType.SEARCH_WAITING_REQUEST, actionType.SEARCH_SUCCESS);
      })
      .catch((err) =>{
        handleServerError(err,dispatch, actionType.SEARCH_WAITING_REQUEST, actionType.SEARCH_FAILED);
      });
  }
};

export const callNewsSearchApi =(pageNumber)=>{
  const {searchText,pageSize,relatedSearchText,didUMean} = store.getState().search;
  let searchTextFormatted = '';
  let autoCorrect = true;

  if (relatedSearchText !== '') {
    autoCorrect = false;
  }

  if (didUMean !== '' && relatedSearchText !== '') {
    searchTextFormatted = (didUMean + ' ' + relatedSearchText).trim();
  }
  else {
    searchTextFormatted = (searchText + ' ' + relatedSearchText).trim();
  }

  const apiUrl = `${API_URL_BASE}/GetNewsSearch?q=${searchTextFormatted}&pageNumber=${pageNumber}&pageSize=${pageSize}&autoCorrect=${autoCorrect}`;

  return (dispatch)=>{
    dispatch({type: actionType.SEARCH_WAITING_REQUEST, value:true});
    dispatch({type: actionType.CLEAR_SEARCH_DATA});

    if (relatedSearchText !== '') {
      dispatch({type:actionType.SET_RELATED_SEARCH_TO_SEARCH_TEXT,value: relatedSearchText});
    }

    axios.get(apiUrl)
      .then((response)=>{
        handleServerSuccess({...response.data, searchedText:searchTextFormatted, currentPage:pageNumber},dispatch, actionType.SEARCH_WAITING_REQUEST, actionType.SEARCH_SUCCESS);

      })
      .catch((err) =>{
        handleServerError(err,dispatch, actionType.SEARCH_WAITING_REQUEST, actionType.SEARCH_FAILED);
      });
  }
};

export const callImageSearchApi = (pageNumber)=>{
  const {searchText,pageSizeImage,pageSizeImageInitial} = store.getState().search;
  let pageSize = pageSizeImage;

  if (pageNumber === 1){
    pageSize = pageSizeImageInitial;
  }

  const apiUrl = `${API_URL_BASE}/GetImageSearch?q=${searchText.trim()}&pageNumber=${pageNumber}&pageSize=${pageSize}&autoCorrect=false`;

  return (dispatch)=>{
    dispatch({type: actionType.IMAGES_WAITING_REQUEST, value:true});
    dispatch({type: actionType.CLEAR_DID_U_MEAN});
    if (pageNumber === 1){
      dispatch({type: actionType.CLEAR_IMAGES_DATA});
    }

    axios.get(apiUrl)
      .then((response)=>{
        if (response.status === 204){
          handleServerError({response:{status: response.status, data:response.statusText}},dispatch, actionType.IMAGES_WAITING_REQUEST, actionType.IMAGES_FAILED);
        } else{
          handleServerSuccess({...response.data, currentPage:pageNumber},dispatch, actionType.IMAGES_WAITING_REQUEST, actionType.IMAGES_SUCCESS);
        }
      })
      .catch((err) =>{
        handleServerError(err,dispatch, actionType.IMAGES_WAITING_REQUEST, actionType.IMAGES_FAILED);
      });
  }
};

export const callAutoCompleteApi =(text)=>{
  return (dispatch)=>{
    const apiUrl = `${SPELLING_API_URL_BASE}/autocomplete?Text=${text.trim()}`;
    dispatch({type: actionType.AUTO_COMPLETE_WAITING, value:true});

    axios.get(apiUrl)
      .then((response)=>{
        handleServerSuccess(response,dispatch, actionType.AUTO_COMPLETE_WAITING, actionType.AUTO_COMPLETE_SUCCESS);
      })
      .catch((err) =>{
        handleServerError(err,dispatch, actionType.AUTO_COMPLETE_WAITING, actionType.AUTO_COMPLETE_FAILED);
      });
  }
};

