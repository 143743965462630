import React from "react";
import ReactDOMServer from 'react-dom/server';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import {isMobileOnly} from "react-device-detect";

import CustomTimeAgo from "../../Common/CustomTimeAgo";
import SearchItemImage from "../../Common/SearchItemImage";
import {reactLinesEllipsesStyleFixes} from "../../../libs/common";

const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLEllipsis);

function SearchItem(props) {
  const {item} = props;
  const isPublishTimeAgoActive=true;

  const descriptionHtml = ReactDOMServer.renderToString(
    <React.Fragment>
      {isPublishTimeAgoActive &&
        <span>
          <CustomTimeAgo date={item.publishedTime} includePreFix={false} includePostFix={true}/>
        </span>
      }
      <span dangerouslySetInnerHTML={{__html:item.snippet}}/>
    </React.Fragment>
  );

  const renderBrowserView = () =>{
    return(
      <div className="item-row">
        {item.imageBase64 !== '' &&
          <div className="image-column">
            <a href={item.imageUrl}>
              <SearchItemImage imgSrc={item.imageBase64} classNames="search-image" alt=''/>
            </a>
          </div>
        }

        <div className={"content-column" + (item.imageBase64 !== '' || item.imageUrl !== '' ? ' pl-2 with-image' : ' without-image')}>
          <a href={item.url} className="title">
            <ResponsiveHTMLEllipsis
              unsafeHTML={item.title}
              maxLine='1'
              ellipsis='...'
              basedOn='words'
            />
          </a>

          <a onClick={(e)=>e.preventDefault()} className="url">
            <ResponsiveHTMLEllipsis
              unsafeHTML={item.url}
              maxLine='1'
              ellipsis='...'
              basedOn='letters'
              style={reactLinesEllipsesStyleFixes}
            />
          </a>

          <div className="description">
            <ResponsiveHTMLEllipsis
              unsafeHTML={descriptionHtml}
              maxLine='2'
              ellipsis='...'
              basedOn='words'
            />
          </div>
        </div>
      </div>
    )
  }

  const renderMobileView = () => {
    return (
      <div className="item-row-mobile">
        <a href={item.url} className="title">
          <ResponsiveHTMLEllipsis
            unsafeHTML={item.title}
            maxLine='1'
            ellipsis='...'
            basedOn='words'
          />
        </a>

        <a href='/' onClick={(e) => e.preventDefault()} className="url">
          <ResponsiveHTMLEllipsis
            unsafeHTML={item.url}
            maxLine='1'
            ellipsis='...'
            basedOn='letters'
            style={reactLinesEllipsesStyleFixes}
          />
        </a>

        <div className="description">
          <ResponsiveHTMLEllipsis
            unsafeHTML={descriptionHtml}
            maxLine='2'
            ellipsis='...'
            basedOn='words'
          />
        </div>
      </div>
    )
  }

  return (
    <>
      {isMobileOnly ?
        renderMobileView()
        :
        renderBrowserView()
      }
    </>
  );
}

export default SearchItem;
