import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useRouteMatch} from "react-router";
import SwiperCore, { Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import ImageDetailMobile from "./ImageDetailMobile";
import {IMAGE_SEARCH_CALL} from "../../../constants/actionTypes";

SwiperCore.use([Virtual]);

function ImageDetailSliderMobile(props){
  const [runRequired,setRunRequired] = useState(false);
  const routeMatch = useRouteMatch();
  const url = routeMatch.params.url;
  const imageUrls = props.searchItemsImage.map((item)=>{ return item.imageUrl; });
  const position = imageUrls.indexOf(atob(url));

  useEffect(()=>{
    if (runRequired && !props.imageSearchAtEnd){
      props.callImageSearchApi(props.currentPageImage + 1);
      setRunRequired(false);
    }
  },[runRequired,props.imageSearchAtEnd]);

  const onSlideChange = (event)=>{}

  const onSwiper = (swiper)=>{}

  const onReachEnd = (event)=>{
    setRunRequired(true);
  }

  const renderSlides = ()=>{
    return props.searchItemsImage.map((item,index)=>{
      return(
        <SwiperSlide item={item} key={index}>
          {/*{(isActive) => (*/}
          {/*  <ImageDetailMobile isVisible={isActive} item={item}/>*/}
          {/*)}*/}
          <ImageDetailMobile item={item}/>
        </SwiperSlide>
      )
    });
  }

  return (
    <React.Fragment>
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        longSwipesRatio={.1}
        onSlideChange={onSlideChange}
        onSwiper={onSwiper}
        onReachEnd={(event)=>onReachEnd(event)}
        virtual
        initialSlide={position}
        // watchSlidesVisibility={true}
      >
        {renderSlides()}
      </Swiper>

      {props.waitingImageSearch &&
        <i className={'bubble-loader'}>
          <span/>
          <span/>
          <span/>
        </i>
      }
    </React.Fragment>
  );
}

const stateToProps = (state) =>{
  return {
    waitingImageSearch:state.search.waitingImageSearch,
    currentPageImage:state.search.currentPageImage,
    searchItemsImage: state.search.searchItemsImage,
    imageSearchAtEnd:state.search.imageSearchAtEnd,
  }
}

const dispatchToProps=(dispatch)=>{
  return{
    callImageSearchApi: (pageNumber) => dispatch({type:IMAGE_SEARCH_CALL, value:{pageNumber}})
  }
};

export default connect(stateToProps,dispatchToProps)(ImageDetailSliderMobile);
