import React from "react";
import {connect} from "react-redux";


function NoResults(props) {
  const getWaiting = () =>{
    if (props.activeTab === 'all') {
      return props.waitingSearch;
    }
    if (props.activeTab === 'news') {
      return props.waitingSearchNews;
    }
    else if (props.activeTab === 'images') {
      return props.waitingImageSearch;
    }
  };

  const getResultsLength = ()=>{
    if (props.activeTab === 'all') {
      return props.searchItems.length;
    }
    else if (props.activeTab === 'news') {
      return props.searchItemsNews.length;
    }
    else if (props.activeTab === 'images') {
      return props.searchItemsImage.length;
    }
  };

  return(
    <React.Fragment>
      {!getWaiting() && getResultsLength() === 0 ?
        <div className="no-results">
          <h1>
            There are no results for <strong>{props.searchedText}</strong>
          </h1>
          <span>
            Check your spelling or try different keywords
          </span>
        </div>
        :
        null
      }
    </React.Fragment>
  )
}

const stateToProps=(state)=>{
  return{
    searchText: state.search.searchText,
    searchedText: state.search.searchedText,
    waitingSearch: state.search.waitingSearch,
    waitingSearchNews: state.search.waitingSearchNews,
    waitingImageSearch: state.search.waitingImageSearch,
    searchItems: state.search.searchItems,
    searchItemsNews: state.search.searchItemsNews,
    searchItemsImage: state.search.searchItemsImage,
    activeTab:state.common.activeTab
  }
};

export default connect(stateToProps,null)(NoResults);
