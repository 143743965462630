import React, {useContext} from 'react';
import {connect} from "react-redux";
import {SET_ACTIVE_TAB, CLEAR_IMAGES_DATA} from "../../constants/actionTypes";
import {withRouter} from "react-router-dom";
import ThemeContext from "../../themeContext";


function TabsBing(props) {
  const theme = useContext(ThemeContext);

  const setActiveTab = (e,type) =>{
    e.preventDefault();
    props.setActiveTab(type);
    if (type === 'all'){
      props.history.push(`/search/${props.searchText}/all`);
    }
    if (type === 'news'){
      props.history.push(`/search/${props.searchText}/news`);
    }
    if (type === 'images'){
      props.clearImageData();
      props.history.push(`/search/${props.searchText}/images`);
    }
  };

  const allTabStyle = props.activeTab === 'all' ? {borderBottom:`3px solid ${theme.primaryColor}`} : null;
  const imagesTabStyle = props.activeTab === 'images' ? {borderBottom:`3px solid ${theme.primaryColor}`} : null;
  const newsTabStyle = props.activeTab === 'news' ? {borderBottom:`3px solid ${theme.primaryColor}`} : null;

  return (
    <div className="tab-container">
      <div className="left-side"/>
      <ul className="nav custom-nav-tab">
        <li className={"nav-item" + (props.activeTab === 'all' ? ' active' : '')} style={allTabStyle}>
          <a className={"nav-link" + (props.activeTab === 'all' ? ' active' : '')} href="/"
             onClick={(e)=>setActiveTab(e,'all')}>ALL</a>
        </li>
        <li className={"nav-item" + (props.activeTab === 'images' ? ' active' : '')} style={imagesTabStyle}>
          <a className={"nav-link" + (props.activeTab === 'images' ? ' active' : '')} href="/"
             onClick={(e)=>setActiveTab(e,'images')}>IMAGES</a>
        </li>
        <li className={'nav-item' + (props.activeTab === 'news' ? ' active' : '')} style={newsTabStyle}>
          <a className={"nav-link" + (props.activeTab === 'news' ? ' active' : '')} href="/"
             onClick={(e)=>setActiveTab(e,'news')}>NEWS</a>
        </li>
      </ul>
    </div>
  );
}

const stateToProps=(state)=>{
  return{
    activeTab: state.common.activeTab,
    searchText: state.search.searchText
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    setActiveTab: (val)=>dispatch({type:SET_ACTIVE_TAB,value:val}),
    clearImageData: () => dispatch({type:CLEAR_IMAGES_DATA})
  }
};

export default withRouter(connect(stateToProps,dispatchToProps)(TabsBing));
