import React from "react";
import {connect} from "react-redux";

import {SET_DOCUMENT_TITLE} from "../../constants/actionTypes";
import './styles.scss';

function Privacy(props) {
  props.setDocumentTitle('Privacy');

  return(
    <div className="container-fluid privacy-container">
      <div className="row justify-content-center mt-md-5">
        <div className="col text-center">
          <h2>Privacy</h2>
        </div>
      </div>
    </div>
  )
}

const dispatchToProps=(dispatch)=>{
  return{
    setDocumentTitle: (val) =>dispatch({type:SET_DOCUMENT_TITLE,value:val})
  }
};

export default connect(null,dispatchToProps)(Privacy);
