import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Form} from "react-bootstrap";
import {withOrientationChange} from "react-device-detect";
import SearchInput from "../SearchInput";
import {CALL_PUBLIC_SEARCH_ENGINE_LIST, SET_SELECTED_SEARCH_ENGINE} from "../../../constants/actionTypes";
import {getDeviceName, getOrientation} from "../../../libs/common";


function Settings(props) {
  const defaultSearchEngineVal = "0";
  const [selectedSearchEngineVal,setSelectedSearchEngineVal] = useState(defaultSearchEngineVal);

  useEffect(()=>{
    props.getAllPublicSearchEngines();
  },[]);

  useEffect(()=>{
    if (props.searchEngines.length>0){
      if (props.selectedSearchEngine){
        setSelectedSearchEngineVal(props.selectedSearchEngine.hash);
      }
    }
  },[props.searchEngines]);

  const onSearchEngineChange = (event) =>{
    const val = event.target.value;
    setSelectedSearchEngineVal(val);
  };

  const onSave = ()=>{
    const searchEngine = props.searchEngines.find((item)=>{
      return item.hash === selectedSearchEngineVal;
    });

    props.saveSelectedSearchEngine(searchEngine);
  };

  const onSaveDefault = ()=>{
    const searchEngine = props.searchEngines.find((item)=>{
      return item.hash === defaultSearchEngineVal;
    });

    props.saveSelectedSearchEngine(searchEngine);
    setSelectedSearchEngineVal(defaultSearchEngineVal);
  };

  const disableSaveButton = ()=>{
    if (props.selectedSearchEngine){
      return selectedSearchEngineVal === props.selectedSearchEngine.hash;
    }

    return false;
  };

  const disableDefaultButton = ()=>{
    return selectedSearchEngineVal === defaultSearchEngineVal;
  }

  return(
    <div className={'cw-container settings ' + getDeviceName() + ' ' + getOrientation(props)}>
      <SearchInput isSettingsPage={true}/>
      <div className="page-title-blank"/>
      <div className="page-title">
        <h2>Settings</h2>
      </div>
      <div className="content container-fluid">
        <div className="row row-top-blank">
          <div className="col-100"/>
        </div>
        <div className="row">
          <div className="d-none d-lg-block col-lg-18 pr-0 left-menu">
            <div className={'d-flex w-100 justify-content-end'}>
              <ul className="nav flex-column" style={{marginTop:'24px'}}>
                <li className="nav-item">
                  <a className="nav-link active" href="#">Customized Search</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-100 col-lg-64 settings">
            <h2>
              Search
            </h2>
            <div className="container-fluid search">
              <div className="row" style={{paddingTop:'30px', paddingBottom:'30px'}}>
                <div className="col-30 d-flex align-items-center" style={{paddingLeft:'20px'}}>
                  <h3>
                    Search Engine
                  </h3>
                </div>
                <div className="col-60">
                  <Form.Control
                    as="select"
                    value={selectedSearchEngineVal}
                    onChange={onSearchEngineChange}
                  >
                    {props.searchEngines.map((searchEngine)=>{
                        return(
                          <option key={searchEngine.hash} value={searchEngine.hash}>{searchEngine.name}</option>
                        )
                      })
                    }
                  </Form.Control>
                </div>
              </div>
            </div>

            <div className="container-fluid save">
              <div className="row" style={{paddingTop:'15px', paddingBottom:'15px'}}>
                <div className="col-100 d-flex align-items-center" style={{paddingLeft:'20px'}}>
                  <button className="btn btn-outline-primary mr-2" onClick={onSave} disabled={disableSaveButton()}>Save</button>
                  <button className="btn btn-outline-secondary" onClick={onSaveDefault} disabled={disableDefaultButton()}>Use default settings</button>
                </div>
              </div>
            </div>
          </div>
          <div className="d-none d-lg-block col-lg-18"/>
        </div>
      </div>
    </div>
  );
}

const stateToProps=(state)=>{
  return{
    searchEngines: state.settings.searchEngines,
    selectedSearchEngine: state.settings.selectedSearchEngine
  }
};

const dispatchToProps = (dispatch) =>{
  return{
    getAllPublicSearchEngines: () => dispatch({type:CALL_PUBLIC_SEARCH_ENGINE_LIST}),
    saveSelectedSearchEngine: (val) => dispatch({type: SET_SELECTED_SEARCH_ENGINE,value:val})
  }
};

export default withOrientationChange(connect(stateToProps,dispatchToProps)(Settings));
