import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {isMobileOnly,withOrientationChange} from "react-device-detect";
import SearchItem from "../SearchItem";
import ResultInfo from "../common/ResultInfo";
import {getDeviceName, getOrientation} from "../../../libs/common";
import SearchIconFloat from "../SearchConsole/SearchIconFloat";


function SearchListWeb(props) {
  const {searchItems} = props;

  useEffect(()=>{
    if (!props.waitingSearch && searchItems.length > 0){
      window.scrollTo(0, 0);
    }
  },[props.waitingSearch, searchItems.length]);

  return (
    <div className={'search-list-container ' + getDeviceName() + ' ' + getOrientation(props)}>
      {!isMobileOnly &&
        <ResultInfo
          waitingSearch={props.waitingSearch}
          itemsLength={props.searchItems.length}
          currentPage={props.currentPage}
          pageSize={props.pageSize}
          totalCount={props.totalCount}
        />
      }

      {props.didYouMean &&
        <React.Fragment>
          <div className="showing-results">
            Showing results for
            <a href="/" onClick="goSearch(didYouMean.trim(),false)"><strong>{props.didYouMean.trim()}</strong></a>
          </div>
          <div>
            Search instead for
            <a href="/" onClick='goSearch(searchText,true)'>{props.searchText}</a>
          </div>
        </React.Fragment>
      }

      <div className="items-container">
        {searchItems.map((item) => {
            return (
              <SearchItem key={item.hash} item={item}/>
            )
          })
        }
      </div>

      {isMobileOnly &&
        <SearchIconFloat/>
      }
    </div>
  )
}

const stateToProps=(state)=>{
  return{
    waitingSearch: state.search.waitingSearch,
    searchItems: state.search.searchItems,
    currentPage:state.search.currentPage,
    didYouMean:state.search.didYouMean,
    totalCount:state.search.totalCount,
    pageSize:state.search.pageSize,
    searchText:state.search.searchText,
  }
};

export default withOrientationChange(connect(stateToProps,null)(SearchListWeb));
