import React from "react";
import SearchListNews from "../SearchListNews";
import RelatedSearch from "../RelatedSearch";
import {isMobile, isTablet, withOrientationChange} from "react-device-detect";


function SearchNews(props) {
  const {isPortrait,isLandscape} = props;

  return(
    <React.Fragment>
      <SearchListNews/>
      {/*{(!isMobile || (isTablet && !isPortrait) || (isTablet && isLandscape)) &&*/}
      {/*  <RelatedSearch/>*/}
      {/*}*/}
    </React.Fragment>
  )
}

export default withOrientationChange(SearchNews);
