export const ENV = process.env.NODE_ENV;

const getApiBaseUrl = () => {
  if (ENV === 'development'){
    return 'https://v2.contextualwebsearch.com/api/Search';
  } else {
    return '/api/Search';
  }
};

const getSpellingApiBaseUrl = () => {
  if (ENV === 'development'){
    return 'https://v2.contextualwebsearch.com/api/spelling';
  } else {
    return '/api/spelling';
  }
};

const getCustomApiBaseUrl = () => {
  if (ENV === 'development'){
    return 'https://v2.contextualwebsearch.com/api/custom';
  } else {
    return '/api/custom';
  }
};


export const API_URL_BASE = getApiBaseUrl();
export const SPELLING_API_URL_BASE = getSpellingApiBaseUrl();
export const CUSTOM_API_BASE_URL = getCustomApiBaseUrl ();
