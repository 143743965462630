import React from "react";
import {withRouter} from "react-router-dom";
import {isMobile,isMobileOnly} from "react-device-detect";


function Footer(props) {
  let additionalClasses = '';
  const location = props.location;
  if (location.pathname === '/'){
    additionalClasses = additionalClasses + ' fixed';
  }

  return(
    <nav className={'navbar navbar-light bg-light cw-footer-container' + (isMobile ? ' mobile':' browser') + additionalClasses}>
      <a className="nav-link nav-link-privacy" href="https://contextualweb.io/" target="_blank">Home site</a>
      <a className="nav-link nav-link-privacy" href="https://rapidapi.com/contextualwebsearch/api/Web%20Search?endpoint=5b864324e4b09cbc25b0013a" target="_blank">Search APIs</a>
      <a className="nav-link nav-link-privacy" href="https://contextualweb.io/custom-search/" target="_blank">Custom Search</a>
      {!isMobileOnly &&
        <>
          <a className="nav-link nav-link-privacy" href="/privacy">Privacy</a>
          <a className="nav-link nav-link-terms" href="/terms">Terms</a>
          <a className="nav-link nav-link-privacy" href="/settings">Settings</a>
        </>
      }
    </nav>
  )
}

export default withRouter(Footer);
