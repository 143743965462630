import React from "react";
import {connect} from "react-redux";

import {SET_DOCUMENT_TITLE} from "../../constants/actionTypes";
import './styles.scss';


function About(props) {
  props.setDocumentTitle('About Us');

  return(
    <div className="container-fluid about-container">
      <div className="row justify-content-center mt-md-5">
        <div className="col text-center">
          <p/>
          <p/>

          <p className="title"><strong>About</strong></p>
          <br/>
          <p>Techongoly</p>
          <p>contextualwebsearch.com is a new search engine with a groundbreaking indexing technology.</p>

          <p>Recent developments in Neuroscience and Brain Science have given us a deeper understating on the human
            brain.</p>

          <p><strong>contextualwebsearch.com mimics the way the human brain indexes its memories. </strong></p>

          <br/>
          <p>The disruptive technology is here to bring down the costs of the search business, getting us closer to our
            vision: making the creation of large scale indexing systems cheap and easy. </p>

          <p/>
          <p/>

          <br/>
          <p>contextualwebsearch.com is also accessible via a free and unlimited API. Billions of webpages, images and
            news with a single <a href="www.contextualdbapi.com"> OneClick API.</a>. </p>
          <br/>
          <p><strong> We don't track you. We don't limit you. We don't chrage you. We don't show ads. </strong></p>
          <br/>

          <p> You can contact us by e-mail: <strong> support@contextualwebsearch.com </strong></p>
        </div>
      </div>
    </div>
  )
}

const dispatchToProps=(dispatch)=>{
  return{
    setDocumentTitle: (val) =>dispatch({type:SET_DOCUMENT_TITLE,value:val})
  }
};

export default connect(null,dispatchToProps)(About);
