// This Image Gallery built help with this original source codes: https://github.com/brunjo/rowGrid
// Built based on this last commit: https://github.com/brunjo/rowGrid/commit/0d0ef66999b590e772b7ece5dc820a30bf38c79a

import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import TimeAgo from "react-timeago";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import englishStrings from "react-timeago/lib/language-strings/en";
import {toast} from "react-toastify";
import InfiniteScroll from "react-infinite-scroller";
import {isMobileOnly} from "react-device-detect";
import {useHistory} from "react-router";
import { useLocation } from 'react-router-dom';

import NoResults from "../NoResults";
import {
  IGNORE_IMAGE_GALLERY_API_CALL,
  IMAGE_SEARCH_CALL
} from "../../../constants/actionTypes";
import "react-image-gallery/styles/scss/image-gallery.scss";
import ImageDetail from "./ImageDetail";


const formatter = buildFormatter(englishStrings);

const DrawImageGallery = (items, containerWidth) => {
  const options = {
    minMargin: 5,
    maxMargin: 15,
    firstItemClass: 'first-item',
    lastRowClass: 'last-row'
  }

  let rowWidth = 0;
  let  rowElems = [];
  let itemsSize = items.length;
  let singleImagePerRow = false;
  let galleryItems = [];

  // read
  let itemAttrs = [];
  let imgHeight = 180;
  const imgHeightStorage = localStorage.getItem('artGalleryImageHeight');

  if (imgHeightStorage){
    imgHeight = parseInt(imgHeightStorage);
  }

  for (let i = 0; i < itemsSize; ++i) {
    let w;
    let h;
    w = items[i].width;
    h = items[i].height;

    if (h < imgHeight || h > imgHeight) {
      let theImageSizeRatio = w / h;
      w = parseInt((imgHeight * theImageSizeRatio).toString());
      h = imgHeight;
    }

    itemAttrs[i] = {
      width: w,
      height: h
    };
  }

  // write
  for (let index = 0; index < itemsSize; ++index) {
    // add element to row
    rowWidth += itemAttrs[index].width;
    rowElems.push(items[index]);

    // check if it is the last element
    if (index === itemsSize - 1) {
      for (let rowElemIndex = 0; rowElemIndex < rowElems.length; rowElemIndex++) {
        // if first element in row
        if (rowElemIndex === 0) {
          rowElems[rowElemIndex].isLastRow = true;
        }

        let style = {};
        style={
          width:itemAttrs[index + parseInt(rowElemIndex) - rowElems.length + 1].width + "px",
          height:itemAttrs[index + parseInt(rowElemIndex) - rowElems.length + 1].height + 'px'
        }

        if (rowElemIndex < rowElems.length - 1) {
          style = {...style,marginRight:options.minMargin + 'px'}
        }

        rowElems[rowElemIndex].style = style;
      }
    }

    // check whether width of row is too high
    if (rowWidth + options.maxMargin * (rowElems.length - 1) > containerWidth || singleImagePerRow) {
      let diff = rowWidth + options.maxMargin * (rowElems.length - 1) - containerWidth;
      let nrOfElems = rowElems.length;

      // change margin
      let rowMargin;
      let maxSave = (options.maxMargin - options.minMargin) * (nrOfElems - 1);
      if (maxSave < diff) {
        rowMargin = options.minMargin;
        diff -= (options.maxMargin - options.minMargin) * (nrOfElems - 1);
      } else {
        rowMargin = options.maxMargin - diff / (nrOfElems - 1);
        diff = 0;
      }

      let rowElem;
      let newHeight = null;
      let widthDiff = 0;

      for (let rowElemIndex = 0; rowElemIndex < rowElems.length; rowElemIndex++) {
        rowElem = rowElems[rowElemIndex];

        let rowElemWidth = itemAttrs[index + parseInt(rowElemIndex) - rowElems.length + 1].width;
        let newWidth = rowElemWidth - (rowElemWidth / rowWidth) * diff;
        newHeight = newHeight || Math.round(itemAttrs[index + parseInt(rowElemIndex) - rowElems.length + 1].height * (newWidth / rowElemWidth));

        if (widthDiff + 1 - newWidth % 1 >= 0.5) {
          widthDiff -= newWidth % 1;
          newWidth = Math.floor(newWidth);
        } else {
          widthDiff += 1 - newWidth % 1;
          newWidth = Math.ceil(newWidth);
        }

        let style = {
          width: newWidth + 'px',
          height: newHeight + 'px'
        }

        if (rowElemIndex < rowElems.length - 1) {
          style={...style,marginRight:rowMargin + 'px'};
        }

        rowElem.style = style;

        if (rowElemIndex === 0) {
          rowElem.isFirstItem = true;
        }

        galleryItems.push(rowElem)
      }

      rowElems = [];
      rowWidth = 0;
    }
  }

  return galleryItems;
}

function buildGallery(imageItems,setGalleryItems,ref) {
  let containerStyle = getComputedStyle(ref.current);
  let containerWidth = Math.floor(ref.current.getBoundingClientRect().width) - parseFloat(containerStyle.getPropertyValue('padding-left')) - parseFloat(containerStyle.getPropertyValue('padding-right'));

  let items = [];
  for (let i = 0; i < imageItems.length; i++) {
    const img = imageItems[i];

    items.push({
      imageUrl: img.imageUrl,
      imageBase64: img.imageBase64,
      width: img.imageWidth,
      height: img.imageHeight,
      classList: [],
      marginRight: '',
      isFirstItem: false,
      isLastRow: false,
      publishedTime: img.publishedTime,
      domainName: img.domainName,
      imageHasError: false
    })
  }

  const galleryItems = DrawImageGallery(items, containerWidth);
  setGalleryItems(galleryItems);
}

function ArtGallery(props) {
  const [showImageDetailModal, setShowImageDetailModal] = useState(false);
  const [galleryItems, setGalleryItems] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const ref = useRef(null);

  useEffect(()=>{
    if (location.hash){
      setShowImageDetailModal(true);
    } else{
      setShowImageDetailModal(false);
    }
  },[location.hash]);

  useLayoutEffect(() => {
    function onWindowResize() {
      buildGallery(props.searchItemsImage,setGalleryItems,ref);
    }

    window.addEventListener('resize', onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
  }, [props.searchItemsImage]);

  useEffect(() => {
    buildGallery(props.searchItemsImage,setGalleryItems,ref);
  }, [props.searchItemsImage]);

  useEffect(() => {
    if (!props.waitingImageSearch && !props.imageSearchSuccess && props.imageSearchError) {
      toast.error('There have an error in server end! Error:' + props.imageSearchError, {
        position: toast.POSITION.TOP_RIGHT,
        draggable: true
      })
    }
  }, [props.waitingImageSearch, props.imageSearchSuccess, props.imageSearchError]); // eslint-disable-line react-hooks/exhaustive-deps

  const onScrollDown = () => {
    if (!props.ignoreImageGalleryApiCall && !props.waitingImageSearch && !props.imageSearchAtEnd) {
      props.callImageSearchApi(props.currentPageImage + 1);
    }
  };

  const gotoImageDetail = (event,item) =>{
    if (isMobileOnly) {
      event.preventDefault();
      history.push('/image_detail_mobile/' + btoa(item.imageUrl));
    } else{
      event.preventDefault();
      setShowImageDetailModal(true);
      history.push('#'+ btoa(item.imageUrl));
    }
  }

  const handleClose = () =>{
    setShowImageDetailModal(false);
    history.goBack();
  }

  if (isMobileOnly){
    props.setIgnoreImageGalleryApiCall(false);
  }

  return (
    <React.Fragment>
      <InfiniteScroll
        pageStart={0}
        threshold={0}
        loadMore={onScrollDown}
        hasMore={!props.waitingImageSearch && !props.imageSearchAtEnd}
        getScrollParent={() => ref}
      >
        <div ref={ref} className={'container-image-gallery' + (isMobileOnly ? ' mobile' : ' browser')}>
          {!props.waitingImageSearch && props.imageSearchAtEnd && galleryItems.length === 0 ?
            <div style={{display:'flex'}}>
              {!isMobileOnly &&
              <div className="left-side-images"/>
              }
              <NoResults/>
            </div>
            :
            galleryItems.map((item, index) => {
              return (
                <div
                  key={index}
                  className={'image-gallery-item' + (item.isFirstItem ? ' first-item' : '') + (item.isLastRow ? ' last-row' : '')}
                  style={item.style}
                >
                  <a href={item.imageUrl} onClick={(event)=>gotoImageDetail(event,item)} target="_blank">
                    {item.imageBase64 !== '' &&
                    <img src={item.imageBase64} alt=""/>
                    }
                  </a>
                  {item.showFooter &&
                  <div className="image-footer">
                    <TimeAgo date={item.publishedTime} formatter={formatter}/>
                    &nbsp;
                    &nbsp;
                    {item.domainName}
                  </div>
                  }
                </div>
              )
            })}
        </div>
        {props.waitingImageSearch &&
          <i className={'bubble-loader'}>
            <span/>
            <span/>
            <span/>
          </i>
        }
      </InfiniteScroll>

      {showImageDetailModal &&
        <ImageDetail
          handleClose={handleClose}
          searchItemsImage={props.searchItemsImage}
        />
      }
    </React.Fragment>
  )
}

const stateToProps=(state)=>{
  return{
    waitingImageSearch:state.search.waitingImageSearch,
    imageSearchSuccess:state.search.imageSearchSuccess,
    imageSearchError:state.search.imageSearchError,
    searchItemsImage:state.search.searchItemsImage,
    imagesItemsChanged:state.search.imagesItemsChanged,
    imageSearchAtEnd:state.search.imageSearchAtEnd,
    currentPageImage:state.search.currentPageImage,
    ignoreImageGalleryApiCall:state.search.ignoreImageGalleryApiCall
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    callImageSearchApi: (pageNumber) => dispatch({type:IMAGE_SEARCH_CALL, value:{pageNumber}}),
    setIgnoreImageGalleryApiCall: (val)=> dispatch({type:IGNORE_IMAGE_GALLERY_API_CALL, value: val})
  }
};

export default connect(stateToProps,dispatchToProps)(ArtGallery);
