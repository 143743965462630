import React from "react";
import { Route, Switch } from "react-router-dom";

import Main from "../components/Main";
import MainBing from "../components/bing/Main";
import SearchComponent from "../components/SearchContainer";
import SearchContainerBing from "../components/bing/SearchContainer";
import About from "../components/About";
// import FreeApi from "../components/Api";
import Blog from "../components/blog";
import Privacy from "../components/Privacy";
import Terms from "../components/terms";
import Settings from "../components/bing/Settings";
import SearchConsoleMobile from "../components/bing/SearchConsole/SearchConsoleMobile";
import ImageDetailSliderMobile from "../components/bing/ArtGallery/ImageDetailSliderMobile";


const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={MainBing} />
      <Route exact path="/search/:text/:tab_name/:page_number?" component={SearchContainerBing} />
      <Route exact path="/search/:text/:tab_name/related/:related_text/:page_number?" component={SearchContainerBing} />
      <Route exact path="/search_console" component={SearchConsoleMobile}/>
      <Route exact path="/image_detail_mobile/:url" component={ImageDetailSliderMobile}/>

      <Route exact path="/old" component={Main} />
      <Route exact path="/old/search/:text/:tab_name?" component={SearchComponent} />
      <Route exact path="/old/search/:text/:tab_name/related/:related_text" component={SearchComponent} />
      <Route exact path="/old/search/:text/related/:related_text" component={SearchComponent} />


      <Route exact path="/about" component={About} />
      {/*<Route exact path="/freeapi" component={FreeApi} />*/}
      <Route exact path="/blog" component={Blog} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/terms" component={Terms} />
        <Route exact path="/settings" component={Settings} />
      <Route exact path="*" component={Main} />
    </Switch>
  );
};

export default Routes;
