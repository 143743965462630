import React from 'react';
import {connect} from "react-redux";
import {SET_ACTIVE_TAB} from "../../constants/actionTypes";
import {callNewsSearchApi, callSearchApi} from "../../actions/search";
import {withRouter} from "react-router-dom";


function Tabs(props) {
  const setActiveTab = (e,type) =>{
    e.preventDefault();
    props.setActiveTab(type);
    if (type === 'all'){
      props.callSearchApi(1);
      props.history.push(`/search/${props.searchText}`);
    }
    if (type === 'news'){
      props.callNewsSearchApi(1);
      props.history.push(`/search/${props.searchText}/news`);
    }
    if (type === 'images'){
      props.history.push(`/search/${props.searchText}/images`);
    }
  };

  return (
    <div className="row" style={{backgroundColor:'#fafafa',marginBottom:'-5px'}}>
      <div className="col-md-8" style={{paddingRight:'0'}}>
        &nbsp;
      </div>
      <div className="col-md-60" style={{paddingLeft:'12px'}}>
        <ul className="nav custom-nav-tab">
          <li className={"nav-item" + (props.activeTab === 'all' ? ' active' : '')}>
            <a className={"nav-link" + (props.activeTab === 'all' ? ' active' : '')} href="/"
               onClick={(e)=>setActiveTab(e,'all')}>All</a>
          </li>
          <li className={"nav-item" + (props.activeTab === 'images' ? ' active' : '')}>
            <a className={"nav-link" + (props.activeTab === 'images' ? ' active' : '')} href="/"
               onClick={(e)=>setActiveTab(e,'images')}>Images</a>
          </li>
          <li className={'nav-item' + (props.activeTab === 'news' ? ' active' : '')}>
            <a className={"nav-link" + (props.activeTab === 'news' ? ' active' : '')} href="/"
               onClick={(e)=>setActiveTab(e,'news')}>News</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

const stateToProps=(state)=>{
  return{
    activeTab: state.common.activeTab,
    searchText: state.search.searchText
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    setActiveTab: (val)=>dispatch({type:SET_ACTIVE_TAB,value:val}),
    callSearchApi: (pageNumber) => dispatch(callSearchApi(pageNumber)),
    callNewsSearchApi:(pageNumber)=>dispatch(callNewsSearchApi(pageNumber))
  }
};

export default withRouter(connect(stateToProps,dispatchToProps)(Tabs));
