import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import {isMobileOnly} from "react-device-detect";

import logo from '../../../assets/images/logo2.png';
import SearchConsole from "../SearchConsole";
import {SET_KEEP_AUTO_COMPLETE_OPENED} from "../../../constants/actionTypes";
import SearchConsoleMobile from "../SearchConsole/SearchConsoleMobile";


function SearchInput(props) {
  const gotoHome = (e) =>{
      e.preventDefault();
      props.setIsBrowserRefreshed(true);
      props.history.push('/');
  };

  return (
    <div className="header">
      {!isMobileOnly &&
        <React.Fragment>
          <div className='logo'>
            <a href={'/'} onClick={(e)=>gotoHome(e)}>
              <img
                src={props.selectedSearchEngine && props.selectedSearchEngine.hash !== "0" ? props.selectedSearchEngine.logoUrl : logo}
                alt=""
              />
            </a>
          </div>

          <SearchConsole isSettingsPage={props.isSettingsPage}/>
        </React.Fragment>
      }

      {isMobileOnly &&
        <React.Fragment>
          <div className='logo-mobile'>
            <a href={'/'} onClick={(e)=>gotoHome(e)}>
              <img
                src={props.selectedSearchEngine && props.selectedSearchEngine.hash !== "0" ? props.selectedSearchEngine.logoUrl : logo}
                alt=""
              />
            </a>
          </div>

          <SearchConsole isSettingsPage={false}/>
        </React.Fragment>
      }
    </div>
  )
}

const stateToProps = (state) =>{
  return{
    selectedSearchEngine: state.settings.selectedSearchEngine
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    setIsBrowserRefreshed: (val) => dispatch({type:SET_KEEP_AUTO_COMPLETE_OPENED, value: val})
  }
};

export default withRouter(connect(stateToProps,dispatchToProps)(SearchInput));
