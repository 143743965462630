import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import { toast } from 'react-toastify';

import {
  IS_AT_MAIN, SET_ACTIVE_TAB,
  SET_CURRENT_PAGE,
  SET_DOCUMENT_TITLE,
  SET_RELATED_SEARCH_TEXT,
  SET_SEARCH_TEXT
} from "../../constants/actionTypes";
import {callNewsSearchApi, callSearchApi} from "../../actions/search";
import SearchInput from "../SearchInput";
import SearchAll from "../SearchAll";
import Tabs from "../tabs";
import SearchNews from "../SearchNews";
import SearchImages from "../SearchImages";
import NoResults from "../NoResults";


function SearchComponent(props) {
  if (props.isAtMain){
    const text = props.match.params.text;
    const relatedText = props.match.params.related_text;
    const tabName = props.match.params.tab_name;

    props.setSearchTextStore(text);
    props.setCurrentPageStore(1);

    if (relatedText != null && relatedText !== '') {
      props.setRelatedSearchTextStore(relatedText);
      props.setDocumentTitle(relatedText);
    }
    else {
      props.setRelatedSearchTextStore('');
      props.setDocumentTitle(text);
    }

    if (tabName == null) {
      props.setActiveTab('all');
      props.callSearchApi(1);
    } else if (tabName === 'news'){
      props.setActiveTab(tabName);
      props.callNewsSearchApi(1);
    } else if (tabName === 'images'){
      props.setActiveTab(tabName);
    }

    props.setIsAtMainFalse();
  }

  useEffect(()=>{
    if (!props.waitingSearch && !props.searchSuccess && props.searchError){
      toast.error('There have an error in server end! Error:' + props.searchError,{
        position: toast.POSITION.TOP_RIGHT,
        draggable: true
      })
    }
  },[props.waitingSearch,props.searchSuccess,props.searchError]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <SearchInput/>
      <Tabs/>
      <NoResults/>
      {(props.activeTab === 'all') &&
        <SearchAll/>
      }
      {(props.activeTab ==='news') &&
        <SearchNews/>
      }
      {(props.activeTab ==='images') &&
        <SearchImages/>
      }
    </React.Fragment>
  )
}

const stateToProps=(state)=>{
  return{
    waitingSearch: state.search.waitingSearch,
    searchSuccess: state.search.searchSuccess,
    searchError: state.search.searchError,
    isAtMain: state.common.isAtMain,
    activeTab:state.common.activeTab
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    setSearchTextStore: (searchText) => dispatch({type:SET_SEARCH_TEXT,value:searchText}),
    setRelatedSearchTextStore: (relatedSearchText) => dispatch({type:SET_RELATED_SEARCH_TEXT,value:relatedSearchText}),
    setCurrentPageStore: (currentPage) => dispatch({type:SET_CURRENT_PAGE,value:currentPage}),
    callSearchApi: (pageNumber) => dispatch(callSearchApi(pageNumber)),
    callNewsSearchApi:(pageNumber)=>dispatch(callNewsSearchApi(pageNumber)),
    setIsAtMainFalse: ()=>dispatch({type:IS_AT_MAIN,value:false}),
    setDocumentTitle: (val) =>dispatch({type:SET_DOCUMENT_TITLE,value:val}),
    setActiveTab: (val)=>dispatch({type:SET_ACTIVE_TAB,value:val}),
  }
};

export default withRouter(connect(stateToProps,dispatchToProps)(SearchComponent));
