import {isMobileOnly, isTablet} from "react-device-detect";

export function capitalizeFirstLetter(str) {
  if (str && str.length>0){
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return null;
}

/*** Debounce function. Borrowed from example pages of this repo. https://github.com/neptunian/react-photo-gallery ***/
// How to Use? Look bellow...
// debounce(()=>{
// executable codes here
// },200)();
export const debounce = (func, wait, immediate) => {
  let timeout;
  return function() {
    const context = this,
      args = arguments;
    let later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const reactLinesEllipsesStyleFixes = {
  overflowWrap: "break-word",
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
  wordWrap: "break-word"
}

export const getDeviceName = () =>{
  let deviceName = 'browser';
  if (isMobileOnly) deviceName = 'mobile';
  if (isTablet) deviceName = 'tablet';

  return deviceName
}

export const getOrientation = ({isLandscape, isPortrait})=> {
  let orientation = '';

  if (isLandscape) {
    orientation = 'landscape';
  } else if (isPortrait) {
    orientation = 'portrait';
  }

  return orientation;
}
