import React from 'react';
import SearchList from "../SearchList";
import RelatedSearch from "../RelatedSearch";


function SearchAll() {
  return(
    <div className="row">
      <div className="col-md-8 d-none d-sm-block">&nbsp;</div>
      <div className="col-md-47 mt-3 mt-sm-0 all-item-container">
        <SearchList/>
      </div>
      <div className="all-related-search-container">
        <RelatedSearch/>
      </div>
    </div>
  )
}

export default SearchAll;
