import {CUSTOM_API_BASE_URL} from "../constants/config";
import axios from "axios";
import {all, call, put, takeLatest} from "redux-saga/effects";
import * as actionType from "../constants/actionTypes";
import {handleApiError, handleApiSuccess} from "../utils/common";


// API calls
function callPublicSearchEngineListApi() {
  const apiUrl = `${CUSTOM_API_BASE_URL}/GetAllPublicSearchEngines`;
  return axios.get(apiUrl);
}

function* publicSearchEngineList() {
  try {
    yield put({type: actionType.PUBLIC_SEARCH_ENGINE_LIST_WAITING, value: true});

    const response = yield call(callPublicSearchEngineListApi);
    yield handleApiSuccess(response.data,actionType.PUBLIC_SEARCH_ENGINE_LIST_WAITING,actionType.PUBLIC_SEARCH_ENGINE_LIST_SUCCESS);
  } catch (error) {
    yield handleApiError(error,actionType.PUBLIC_SEARCH_ENGINE_LIST_WAITING,actionType.PUBLIC_SEARCH_ENGINE_LIST_FAILED)
  }
}

// Settings saga: watches for search actions dispatched to the store
export function* settingsSaga() {
  yield all([
    yield takeLatest(actionType.CALL_PUBLIC_SEARCH_ENGINE_LIST, publicSearchEngineList)
  ]);
}
