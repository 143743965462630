import {
  WAITING_REQUEST,
  SET_WINDOW_INNER_WIDTH,
  SET_DOCUMENT_TITLE,
  IS_AT_MAIN,
  SET_ACTIVE_TAB,
  SET_KEEP_AUTO_COMPLETE_OPENED,
  SET_IS_FOOTER_REQUIRED
} from "../constants/actionTypes";

const initialState={
  documentTitle:'ContextualWeb',
  waitingRequest:false,
  windowInnerWidth:0,
  isAtMain:true,
  activeTab:'all',
  keepAutoCompleteOpened:true,
  isFooterRequired:true
};

export default (state=initialState,action)=>{
  switch (action.type){
    case WAITING_REQUEST:{
      return {...state,waitingRequest:action.value};
    }
    case SET_WINDOW_INNER_WIDTH:{
      return {...state,windowInnerWidth:action.value};
    }
    case SET_DOCUMENT_TITLE:{
      return {...state,documentTitle:action.value};
    }
    case IS_AT_MAIN:{
      return {...state,isAtMain: action.value}
    }
    case SET_ACTIVE_TAB:{
      return {...state,activeTab: action.value}
    }
    case SET_KEEP_AUTO_COMPLETE_OPENED:{
      return {...state,keepAutoCompleteOpened: action.value}
    }
    case SET_IS_FOOTER_REQUIRED:{
      return {...state,isFooterRequired: action.value}
    }
    default:
      return state;
  }
}
