import React, {useLayoutEffect, useState, useRef} from "react";

// Custom Hook to get image and window width when browser is resized
function useImageAndWindowWidth(ref) {
  const [width, setWidth] = useState([0,0]);

  useLayoutEffect(() => {
    function updateSearchItemResize() {
      if (window.innerWidth < 768){
        setWidth([ref.current.clientWidth,window.innerWidth]);
      }
    }
    window.addEventListener('resize', updateSearchItemResize);
    updateSearchItemResize();
    return () => window.removeEventListener('resize', updateSearchItemResize);
  }, []);

  return width;
}

function SearchItemImage(props) {
  const ref = useRef(null);
  const [imageWidth, windowWidth] = useImageAndWindowWidth(ref);

  return(
    <React.Fragment>
      {windowWidth === 0 ?
        <img ref={ref} src={props.imgSrc} className={props.classNames} alt=""/>
        :
        <img ref={ref} src={props.imgSrc} className={props.classNames} style={{height:imageWidth+'px'}} alt=""/>
      }
    </React.Fragment>
  )
}

export default SearchItemImage;
