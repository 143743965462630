import React from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {withStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const StyledTooltip = withStyles({
  tooltip: {
    fontSize: "14px",
    color: "#fff",
    backgroundColor: "#2A3E52",
    padding: "15px 15px"
  },
  arrow:{
    color: "#2A3E52"
  }
})(Tooltip);

function InfoIconWithTooltips (props) {
  return(
    <StyledTooltip title={props.title} arrow>
      <InfoOutlinedIcon style={{ color: "#B1B1B1", marginLeft: "5px", marginTop: "-4px" }}/>
    </StyledTooltip>
  )
}

export default InfoIconWithTooltips;
