import React, {useState} from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router";
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import OpenInBrowserSharpIcon from '@material-ui/icons/OpenInBrowserSharp';

import {getDeviceName, getOrientation} from "../../../libs/common";
import BorderLinearProgress from "./BorderLinearProgress";
import InfoIconWithTooltips from "./InfoIconWithTooltips";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const columns = [
  { id: 'name', label: 'Name', width: 300 },
  {id: 'frequencyDisplay', label: 'Frequency Display', width: 80},
  { id: 'frequencyAsStr', label: 'Frequency', width: 125 }
];

const useStyles = (queryTableHover)=>{
  let overFlowY = "hidden";
  if (queryTableHover){
    overFlowY = "auto";
  }

 return makeStyles({
   root: {
     width: '100%',
   },
   container: {
     height: 204,
     overflowY: overFlowY
   },
 })();
};

const StyledTooltip = withStyles({
  tooltipPlacementBottom: {
    margin: "4px 0",
  },
})(Tooltip);

function QueryAnalysis (props) {
  const [queryTableHover,setQueryTableHover] = useState(false);
  const [domainTableHover,setDomainTableHover] = useState(false);
  const [domainNameHover,setDomainNameHover] = useState(false);
  const classesQueryTable = useStyles(queryTableHover);
  const classesDomainTable = useStyles(domainTableHover);
  const history = useHistory();

  const goSearch = (e,text) =>{
    e.preventDefault();
    const searchText = text.replace(/(<([^>]+)>)/ig, '');

    history.push(`/search/${searchText}/${props.activeTab}`);
  };

  const onMouseEnterQueryTable = () =>{
    setQueryTableHover(true);
  }

  const onMouseLeaveQueryTable = () =>{
    setQueryTableHover(false);
  }

  const onMouseEnterDomainTable = () =>{
    setDomainTableHover(true);
  }

  const onMouseLeaveDomainTable = () =>{
    setDomainTableHover(false);
  }

  const onMouseEnterDomainName = () =>{
    setDomainNameHover(true);
  }

  const onMouseLeaveDomainName = () =>{
    setDomainNameHover(true);
  }

  return (
    <React.Fragment>
      {((props.activeTab === 'all' && (props.relatedQueryRows.length > 0 || props.relatedDomainRows.length > 0))) &&
        <div className={'query-analysis-container ' + getDeviceName() + ' ' + getOrientation(props)}>
          <div>
            <span className="title" style={{marginLeft:"-2px"}}>Query Analysis</span>
            <span className="search-text" dangerouslySetInnerHTML={{__html: props.queryParsing}}/>
          </div>

          {props.relatedQueryRows.length > 0 &&
            <div className="query-rows-container">
              <div className="title">
                Similar Queries
                <InfoIconWithTooltips title="Top similar queries. Include percentages indicating the share of table number of similar queries."/>
              </div>
              <Paper className={classesQueryTable.root}>
                <TableContainer onMouseEnter={onMouseEnterQueryTable} onMouseLeave={onMouseLeaveQueryTable} className={classesQueryTable.container}>
                  <Table style={{width: "auto", tableLayout: "auto"}}>
                    <TableBody>
                      {props.relatedQueryRows.map((row) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            {columns.map((column) => {
                              const value = row[column.id];

                              return (
                                <TableCell key={column.id} component="th" scope="row" width={column.width}>
                                  {column.id === 'name' &&
                                    <a href="/" onClick={(event) => goSearch(event, value)} className="similar-text">
                                      <span dangerouslySetInnerHTML={{__html: value}}/>
                                      {/*<HighlighterText*/}
                                      {/*  searchWords={props.searchTextForHighlight}*/}
                                      {/*  textToHighlight={value}*/}
                                      {/*/>*/}
                                    </a>
                                  }

                                  {column.id === 'frequencyAsStr' &&
                                    <StyledTooltip title={parseFloat(value) * 100} arrow>
                                      <div style={{padding: "0 0 3px 0"}}>
                                        <BorderLinearProgress progressBarColor={row["color"]} value={parseFloat(value) * 100}/>
                                      </div>
                                    </StyledTooltip>
                                  }

                                  {column.id === 'frequencyDisplay' &&
                                    <div style={{color:"#556576"}}>{value}</div>
                                  }
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          }

          {props.relatedDomainRows.length>0 &&
            <div className="query-domain-container">
            <div className="title">
              Popular Domains for Query
              <InfoIconWithTooltips title="Top related domains. Include percentages indicating the share of the total number of relevant webpages."/>
            </div>
            <Paper className={classesDomainTable.root}>
              <TableContainer onMouseEnter={onMouseEnterDomainTable} onMouseLeave={onMouseLeaveDomainTable} className={classesDomainTable.container}>
                <Table style={{ width: "auto", tableLayout: "auto" }}>
                  <TableBody>
                    {props.relatedDomainRows.map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          <TableCell component="th" scope="row" width={300} style={{display:"flex"}} onMouseEnter={onMouseEnterDomainName} onMouseLeave={onMouseLeaveDomainName}>
                            <div style={{width: "16px", height: "16px",marginRight:"10px"}}>
                              {row['favIconBase64'] &&
                              <img src={row['favIconBase64']} style={{width: "100%", height: "100%"}} alt=""/>
                              }
                            </div>
                            <a href={"//"+row['name']} target="_blank">{row['name']}</a>
                            {/*{domainNameHover &&*/}
                            {/*  <a href={"//"+row['name']} target="_blank">*/}
                            {/*    <OpenInBrowserSharpIcon style={{ color: "#556576", width:"20px", height:"20px", marginLeft:"5px" }}/>*/}
                            {/*  </a>*/}
                            {/*}*/}
                          </TableCell>
                          <TableCell component="th" scope="row" width={80}>
                            <span style={{color:"#556576"}}>{row["frequencyDisplay"]}</span>
                          </TableCell>
                          <TableCell component="th" scope="row" width={125}>
                            <Tooltip title={parseFloat(row["frequencyAsStr"])*100} arrow>
                              <div style={{padding: "0 0 3px 0"}}>
                                <BorderLinearProgress variant="determinate" progressBarColor={row["color"]} value={parseFloat(row["frequencyAsStr"])*100}/>
                              </div>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
          }
        </div>
      }
    </React.Fragment>
  )
}

const stateToProps=(state)=>{
  return{
    activeTab: state.common.activeTab,
    searchText: state.search.searchText,
    relatedSearches: state.search.relatedSearches,
    queryParsing: state.search.queryParsing,
    relatedQueryRows: state.search.relatedQueryRows,
    relatedDomainRows: state.search.relatedDomainRows,
    searchTextForHighlight: state.search.searchTextForHighlight,
  }
};

export default connect(stateToProps,null)(QueryAnalysis);
