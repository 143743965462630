import React from 'react';
import {connect} from "react-redux";

import logo from '../../assets/images/logo.png';
import SearchConsole from "../SearchConsole";
import {IS_AT_MAIN} from "../../constants/actionTypes";

function Main(props) {
  props.setIsAtMainTrue();

  return (
    <div className="container-fluid">
      <div className="row mt-0 mt-md-5">
        <div className="col-100">&nbsp;</div>
      </div>
      <div className="row mt-0 mt-md-5">
        <div className="col-100">&nbsp;</div>
      </div>
      <div className="row justify-content-center mt-md-5 mb-md-4 mb-2">
        <div className="col-70 col-md-30">
          <img src={logo} style={{width: "100%", height: "auto"}} alt={''}/>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-90 col-md-40">
          <SearchConsole/>
        </div>
      </div>
      <div className="row justify-content-center mt-2">
        <div className="col-90 col-md-40">
          <p className="text-center">
            Mimics the human brain
          </p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-90 col-md-40 text-center">
          <a href="https://rapidapi.com/contextualwebsearch/api/Web%20Search" className="ulimited-free-api">Get access
            to the Search API</a>
        </div>
      </div>
    </div>
  )
}

const dispatchToProps=(dispatch)=>{
  return{
    setIsAtMainTrue: ()=>dispatch({type:IS_AT_MAIN,value:true})
  }
};


export default connect(null,dispatchToProps)(Main);
