import React from "react";
import moment from "moment";
import TimeAgo from 'react-timeago';

const formatter = (value, unit, suffix,date, includePreFix, includePostFix) => {
  const localNow = new Date();
  const now = new Date(localNow.getUTCFullYear(), localNow.getUTCMonth(), localNow.getUTCDate(), localNow.getUTCHours(), localNow.getUTCMinutes(), localNow.getUTCSeconds());

  const newDate = moment(date).toDate();
  // time since message was sent in seconds
  const delta = (now - newDate.getTime()) / 1000;
  let result = '';

  // format string
  if (delta < 60) { // sent in last minute
    result = `${Math.floor(delta)}s ago`;
  } else if (delta < 3600) { // sent in last hour
    result = `${Math.floor(delta / 60)}m ago`;
  } else if (delta < 86400) { // sent on last day
    result = `${Math.floor(delta / 3600)}h ago`;
  } else { // sent more than one day ago
    let minDate = moment.utc("0001-01-01");

    if (moment(newDate) > minDate) {
      result = moment(newDate).format('MM/DD/YYYY');
    }
    else {
      result = '';
    }
  }

  if (result && includePostFix) {
    result = result + ' · ';
    return result;
  }
  if (result && includePreFix) {
    result = '● ' + result;
    return result;
  }

  return result;
};

function CustomTimeAgo(props) {
  return(
    <TimeAgo title="" date={props.date} formatter={(value, unit, suffix,date) => formatter(value, unit, suffix,date, props.includePreFix,props.includePostFix)}/>
  )
}

export default CustomTimeAgo;
