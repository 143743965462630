import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {isMobile,isMobileOnly,isTablet,withOrientationChange} from "react-device-detect";
import {getDeviceName, getOrientation} from "../../../libs/common";


function RelatedSearch(props) {
  let relatedSearches = [];
  if (props.activeTab === 'all') {
    relatedSearches = props.relatedSearches;
  } else if (props.activeTab === 'news'){
    relatedSearches = props.relatedSearchesNews;
  }

  if (isMobileOnly){
    relatedSearches = relatedSearches.slice(0,8);
  }

  const goSearch = (e,relatedSearch) =>{
    e.preventDefault();

    let searchText = '';
    let didUMean = '';

    const routeVal = relatedSearch.replace(/(<([^>]+)>)/ig, '');
    if (props.activeTab === 'all') {
      didUMean = props.didUMean;
    } else if (props.activeTab === 'news'){
      didUMean = props.didUMeanNews;
    }

    if (didUMean !== '') {
      searchText = didUMean;
    }
    else {
      searchText = props.searchText;
    }

    props.history.push(`/search/${searchText}/${props.activeTab}/related/${routeVal}`);
  };

  const renderDesktop = ()=> {
    return relatedSearches.map((item, index) => {
      return (
        <li key={index}>
          <a href="/" onClick={(e) => goSearch(e, item)} className="title">
            <span dangerouslySetInnerHTML={{__html: item}}/>
          </a>
        </li>
      );
    })
  }

  const renderMobile = ()=> {
    return relatedSearches.map((item, index) => {
      return (
        <li key={index}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <a href="/" onClick={(e) => goSearch(e, item)} className="title">
              <span dangerouslySetInnerHTML={{__html: item}}/>
            </a>
            <div className="search-icon"/>
          </div>
        </li>
      )
    });
  }

  const renderTabletPortrait = ()=>{
    return relatedSearches.map((item, index) => {
      return (
        <li key={index}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <a href="/" onClick={(e) => goSearch(e, item)} className="title">
              <span dangerouslySetInnerHTML={{__html: item}}/>
            </a>
            <div className="search-icon"/>
          </div>
        </li>
      )
    });
  }

  return(
    <React.Fragment>
      {((props.activeTab === 'all' && props.relatedSearches.length > 0) || (props.activeTab === 'news' && props.relatedSearchesNews.length > 0)) &&
        <div className={'related-search-container ' + getDeviceName() + ' ' + getOrientation(props)}>
          <h2>Related searches</h2>
          <ul>
            {isMobileOnly &&
              renderMobile()
            }

            {(isTablet && props.isPortrait) &&
              renderTabletPortrait()
            }

            {(!isMobile || (isTablet && props.isLandscape)) &&
              renderDesktop()
            }
          </ul>
        </div>
      }
    </React.Fragment>
  );
}

const stateToProps=(state)=>{
  return{
    searchText: state.search.searchText,
    relatedSearches: state.search.relatedSearches,
    relatedSearchesNews: state.search.relatedSearchesNews,
    didUMean: state.search.didUMean,
    didUMeanNews: state.search.didUMeanNews,
    activeTab: state.common.activeTab
  }
};

export default withOrientationChange(withRouter(connect(stateToProps,null)(RelatedSearch)));
