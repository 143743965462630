import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {CHANGE_RELATED_SEARCH_TEXT} from "../../constants/actionTypes";

function RelatedSearch(props) {
  const goSearch = (e,relatedSearch) =>{
    e.preventDefault();

    let searchText = '';
    const routeVal = relatedSearch.replace(/(<([^>]+)>)/ig, '');

    if (props.didUMean !== '') {
      searchText = props.didUMean;
    }
    else {
      searchText = props.searchText;
    }

    if (props.activeTab === 'news') {
      props.history.push(`/search/${searchText}/news/related/${routeVal}`);
    }
    else {
      props.history.push(`/search/${searchText}/related/${routeVal}`);
    }
  };

  return(
    <React.Fragment>
      {props.relatedSearches.length>0 &&
        <React.Fragment>
          {props.activeTab === 'all' &&
            <React.Fragment>
              <div className="row mt-3 mb-2">
                <div className="col-md-100">
                  <span>Show Results Related To:</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-100">
                  {props.relatedSearches.map((item,index)=>{
                    return(
                      <div key={index} className="row">
                        <div className="col-100">
                          <a href="/" onClick={(e)=>goSearch(e,item)} className="search-title">
                            <span dangerouslySetInnerHTML={{__html:item}}/>
                          </a>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </React.Fragment>
          }

          {props.activeTab === 'news' &&
            <React.Fragment>
              <div className="row mt-3 mb-2">
                <div className="col-md-100">
                  <span style={{fontSize:'18px'}}>Show Results Related To:</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-100">
                  <div className="row">
                    {props.relatedSearches.map((item,index)=>{
                      return(
                        <div key={index} className="col-100 col-md-50">
                          <a href="/" onClick={(e) => goSearch(e,item)} className="search-title" style={{fontSize:'14px'}}>
                            <span dangerouslySetInnerHTML={{__html:item}}/>
                          </a>
                        </div>
                      )})
                    }
                  </div>
                </div>
              </div>
            </React.Fragment>
          }
        </React.Fragment>
      }
    </React.Fragment>
  );
}

const stateToProps=(state)=>{
  return{
    searchText: state.search.searchText,
    relatedSearches: state.search.relatedSearches,
    didUMean: state.search.didUMean,
    activeTab: state.common.activeTab
  }
};

export default withRouter(connect(stateToProps,null)(RelatedSearch));
