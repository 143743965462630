import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from '@material-ui/core/styles';


function BorderLinearProgress (props){
  const {classes} = props;

  const renderProgressbar = () =>{
    return (
      <LinearProgress variant="determinate" value={props.value} {...props} classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColor}}/>
    )
  }

  return renderProgressbar();
}

const styles = (props) => ({
  root: {
    height: 4,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#eeeeee",
  },
  barColor: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  }
});

export default withStyles(styles)(BorderLinearProgress)
