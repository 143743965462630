import React from "react";
import SearchList from "../SearchList";


function SearchNews() {
  return(
    <div className="row">
      <div className="col-md-8 d-none d-sm-block">&nbsp;</div>
      <div className="col-md-35 mt-3 mt-sm-0 news-item-container">
        <SearchList/>
      </div>
    </div>
  )
}

export default SearchNews;
