import React from "react";
import {isMobileOnly} from "react-device-detect";

import ReactPaginate from "../../../libs/react-paginate"
import iconsImg from "../../../assets/images/icons.png";


function CustomPagination(props) {
  return(
    <React.Fragment>
      {!props.waitingSearch && props.totalCount>0 &&
        <div className={'pagination-container' + (isMobileOnly ? ' mobile' : ' browser')}>
          <ReactPaginate
            previousLabel={<img src={iconsImg} alt=""/>}
            nextLabel={<img src={iconsImg} alt=""/>}
            pageCount={props.pageCount}
            forcePage={props.currentPage}
            marginPagesDisplayed={1}
            pageRangeDisplayed={isMobileOnly ? 100 : props.paginationCtrlMaxSize}
            onPageChange={props.onPageChange}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            breakClassName="page-item"
            breakLabel={<a className="page-link" href='/'>...</a>}
            pageClassName="page-item"
            previousClassName="previous-item"
            nextClassName="next-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            isSingleItem={isMobileOnly}
          />
        </div>
      }
    </React.Fragment>
  )
}

export default CustomPagination;
