import {applyMiddleware,createStore,compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
// import {createLogger} from "redux-logger";
import storage from 'redux-persist/lib/storage';
import {createBrowserHistory} from 'history';
import rootReducer from './reducer';

import createSagaMiddleware from "redux-saga";
import sagas from "./sagas";
const sagaMiddleware = createSagaMiddleware();

// const middleware = applyMiddleware(createLogger({ collapsed:true }),sagaMiddleware)
const middleware = applyMiddleware(sagaMiddleware)

const persistConfig = {
  key: 'root',
  storage,
  // these reducers data will be available in store even after page refresh. clears on local storage clearance
  whitelist: ["settings"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(
  middleware
);

export const store = createStore(persistedReducer, enhancer);
export const persistor = persistStore(store);
export const  history=createBrowserHistory();

sagas.forEach(saga=> sagaMiddleware.run(saga));
