import React from 'react';
import {connect} from "react-redux";
import {isMobileOnly} from "react-device-detect";
import {withOrientationChange} from "react-device-detect";

import logo from '../../../assets/images/logo.png';
import SearchConsole from "../SearchConsole";
import {IS_AT_MAIN} from "../../../constants/actionTypes";
import {getDeviceName, getOrientation} from "../../../libs/common";
import SearchConsoleMobile from "../SearchConsole/SearchConsoleMobile";

function Main(props) {
  const mobileDeviceOrientation = getOrientation(props);
  props.setIsAtMainTrue();

  const renderLogoImage = ()=>{
    let imageStyle = {height: "auto", objectFit:"cover"};

    if (isMobileOnly){
      if (mobileDeviceOrientation === 'landscape'){
        imageStyle = {...imageStyle,width:'40%'};
      } else{
        imageStyle = {...imageStyle,width:'75%'};
      }
    } else{
      imageStyle = {...imageStyle,width:'460px', marginBottom:"25px"};
    }

    if (props.selectedSearchEngine && props.selectedSearchEngine.hash !== "0"){
      return(
        <img
          className={'img-logo'}
          src={props.selectedSearchEngine.imageUrl}
          style={imageStyle}
          alt={''}
        />
      )
    }

    return (
      <img
        className={'img-logo'}
        src={logo}
        style={imageStyle}
        alt={''}
      />
    )
  }

  return (
    <div className={"cw-container main " + getDeviceName() + ' ' + mobileDeviceOrientation}>
      {!isMobileOnly &&
        <div className={'search-container browser'}>
          {renderLogoImage()}

          <div className="console-container">
            <SearchConsole isSettingsPage={false}/>
          </div>

          <p className="text-center" style={{marginTop:"35px",marginBottom:"0"}}>
            Mimics the human brain
          </p>

          <div style={{marginTop:"25px"}}>
            <a href="https://contextualweb.io/" className="ulimited-free-api">contextualweb.io</a>
          </div>
        </div>
      }
      {isMobileOnly &&
        <div className={'search-container mobile'}>
          {renderLogoImage()}

          <div className={'console-container'}>
            <SearchConsole isSettingsPage={false}/>
          </div>
          <p className="text-center" style={{marginBottom:"0"}}>
            Mimics the human brain
          </p>

          <div className="link">
            <a href="https://contextualweb.io/" className="ulimited-free-api">contextualweb.io</a>
          </div>
        </div>
      }
    </div>
  )
}

const stateToProps=(state)=>{
  return{
    selectedSearchEngine: state.settings.selectedSearchEngine
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    setIsAtMainTrue: ()=>dispatch({type:IS_AT_MAIN,value:true})
  }
};

export default withOrientationChange(connect(stateToProps,dispatchToProps)(Main));
