import React, {useContext} from "react";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {CSSTransition} from 'react-transition-group';
import ThemeContext from "../../../themeContext";

function SearchIconFloat (props){
  const theme = useContext(ThemeContext);

  const openSearchConsole = () =>{
    window.scrollTo(0,0);
    props.history.push('/search_console');
  }

  return(
    <React.Fragment>
      <CSSTransition
        in={props.isFloatingSearchIconVisible}
        appear={true}
        unmountOnExit
        classNames="animate"
        timeout={500}
      >
        <div className={'float-icon'}>
          <button className="btn-search" onClick={openSearchConsole} style={{color: theme.primaryColor}}>
            <FontAwesomeIcon icon={faSearch}/>
          </button>
        </div>
      </CSSTransition>
    </React.Fragment>
  )
}

const stateToProps = (state) =>{
  return{
    isFloatingSearchIconVisible: state.search.isFloatingSearchIconVisible
  }
};

export default withRouter(connect(stateToProps,null)(SearchIconFloat));
