import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";

import logo from '../../assets/images/logo.png';
import SearchConsole from "../SearchConsole";
import {SET_KEEP_AUTO_COMPLETE_OPENED} from "../../constants/actionTypes";


function SearchInput(props) {
  const gotoHome = (e) =>{
      e.preventDefault();
      props.setIsBrowserRefreshed(true);
      props.history.push('/');
  };

  return (
    <div className="row" style={{backgroundColor:'#fafafa', marginBottom:'-5px'}}>
      <div className="col-md-8" style={{paddingRight:'0'}}>
        <a href={'/'} onClick={(e)=>gotoHome(e)}>
          <img src={logo} className="mt-1 main-logo" style={{marginLeft: '-5px'}} alt=""/>
        </a>
      </div>
      <div className="col-md-82" style={{paddingLeft:'8px'}}>
        <SearchConsole/>
      </div>
    </div>
  )
}

const dispatchToProps=(dispatch)=>{
  return{
    setIsBrowserRefreshed: (val) => dispatch({type:SET_KEEP_AUTO_COMPLETE_OPENED, value: val})
  }
};

export default withRouter(connect(null,dispatchToProps)(SearchInput));
