import React from "react";

function ResultInfo(props) {
  return(
    <React.Fragment>
      {!props.waitingSearch && props.itemsLength > 0 &&
        <div className="result-info">
          {props.currentPage > 1 &&
            <span>{((props.currentPage-1)*props.pageSize)+1}- {props.currentPage*props.pageSize} Of </span>
          }
          <span>{props.totalCount.toLocaleString('en')} Results</span>
        </div>
      }
    </React.Fragment>
  )
}

export default ResultInfo;
