import React from 'react';
import {connect} from "react-redux";

import ReactPaginate from 'react-paginate';
import SearchItem from "../SearchItem";
import NewsItem from "../NewsItem";
import RelatedSearch from "../RelatedSearch";
import {callNewsSearchApi, callSearchApi} from "../../actions/search";

function SearchList(props) {
  const {searchItems,totalCount,pageSize} = props;
  const pageCount = Math.ceil(totalCount/pageSize);

  const onPageChanged = (data) =>{
    const {selected} = data;

    if (props.activeTab === 'all'){
      props.callSearchApi(selected+1);
    } else if (props.activeTab === 'news'){
      props.callNewsSearchApi(selected+1);
    }
  };

  return (
    <React.Fragment>
      {!props.waitingSearch && searchItems.length > 0 &&
        <div className="row mt-3 mb-2 d-none d-sm-block result-info">
          <div className="col-100" style={{paddingLeft:'8px', fontSize:'15px', fontWeight:'500',color:'#999'}}>
            {props.currentPage > 1 &&
            <span style={{marginLeft: '2px'}}>Page {props.currentPage} of </span>
            }
            <span style={{marginLeft: '2px'}}>Top {props.totalCount} results ({props.responseTime})</span>
          </div>
        </div>
      }

      {props.didYouMean &&
        <div className="row mb-3 mt-3 did-you-mean">
          <div className="col-100">
            <div className="showing-results">
              Showing results for
              <a href="/" onClick="goSearch(didYouMean.trim(),false)"><strong>{props.didYouMean.trim()}</strong></a>
            </div>
            <div>
              Search instead for
              <a href="/" onClick='goSearch(searchText,true)'>{props.searchText}</a>
            </div>
          </div>
        </div>
      }

      <div className="row">
        <div className="col-100 pl-2 pr-2">
          {props.activeTab === 'all' &&
            searchItems.map((item) => {
              return (
                <SearchItem key={item.hash} item={item}/>
              )
            })
          }
        </div>

        <div className="col-100 pl-2 pr-2">
          {props.activeTab === 'news' &&
          searchItems.map((item) => {
            return (
              <NewsItem key={item.hash} item={item}/>
            )
          })
          }
        </div>
      </div>

      {props.activeTab ==='news' &&
        <div className="row mb-0 mb-sm-3 related-search-news">
          <div className="col-100">
            <RelatedSearch/>
          </div>
        </div>
      }

      <div className="row mb-5">
        <div className="col-100 d-flex justify-content-center pagination-column">
          {!props.waitingSearch && props.totalCount>0 &&
            <ReactPaginate
              previousLabel={'« Previous'}
              nextLabel={'Next »'}
              pageCount={pageCount}
              forcePage={(props.currentPage-1)}
              marginPagesDisplayed={1}
              pageRangeDisplayed={props.paginationCtrlMaxSize}
              onPageChange={onPageChanged}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              breakClassName="page-item"
              breakLabel={<a className="page-link" href='/'>...</a>}
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
            />
          }
        </div>
      </div>

      <div className="row d-none d-md-block m-md-2">
        <div className="col">
          &nbsp;
        </div>
      </div>
    </React.Fragment>
  )
}

const stateToProps=(state)=>{
  return{
    activeTab:state.common.activeTab,
    waitingSearch: state.search.waitingSearch,
    searchItems: state.search.searchItems,
    currentPage:state.search.currentPage,
    didYouMean:state.search.didYouMean,
    totalCount:state.search.totalCount,
    pageSize:state.search.pageSize,
    responseTime:state.search.responseTime,
    searchText:state.search.searchText,
    paginationCtrlMaxSize: state.search.paginationCtrlMaxSize
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    callSearchApi: (pageNumber) => dispatch(callSearchApi(pageNumber)),
    callNewsSearchApi:(pageNumber)=>dispatch(callNewsSearchApi(pageNumber))
  }
};

export default connect(stateToProps,dispatchToProps)(SearchList);
