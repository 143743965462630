import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {isMobileOnly} from "react-device-detect";
import NewsItem from "../NewsItem";
import ResultInfo from "../common/ResultInfo";


function SearchListNews(props) {
  const {searchItemsNews} = props;

  useEffect(()=>{
    if (!props.waitingSearchNews && searchItemsNews.length > 0){
      window.scrollTo(0, 0);
    }
  },[props.waitingSearchNews, searchItemsNews.length]);

  return (
    <div className={'search-list-container ' + (isMobileOnly ? 'mobile' : 'browser')}>
      {!isMobileOnly &&
        <ResultInfo
          waitingSearch={props.waitingSearchNews}
          itemsLength={props.searchItemsNews.length}
          currentPage={props.currentPageNews}
          pageSize={props.pageSizeNews}
          totalCount={props.totalCountNews}
        />
      }
      <div className="items-container">
        {searchItemsNews.map((item) => {
            return (
              <NewsItem key={item.hash} item={item}/>
            )
          })
        }
      </div>
    </div>
  )
}

const stateToProps=(state)=>{
  return{
    waitingSearchNews: state.search.waitingSearchNews,
    searchItemsNews: state.search.searchItemsNews,
    currentPageNews:state.search.currentPageNews,
    totalCountNews:state.search.totalCountNews,
    pageSizeNews:state.search.pageSizeNews,
    searchText:state.search.searchText
  }
};

export default connect(stateToProps,null)(SearchListNews);
