import React from "react";
import {connect} from "react-redux";


function NoResults(props) {
  const getWaiting = () =>{
    if (props.activeTab === 'all' || props.activeTab === 'news') {
      return props.waitingSearch;
    }
    else if (props.activeTab === 'images') {
      return props.waitingImageSearch;
    }
  };

  const getResultsLength = ()=>{
    if (props.activeTab === 'all') {
      return props.searchItems.length;
    }
    else if (props.activeTab === 'images') {
      return props.searchItemsImage.length;
    }
    else if (props.activeTab === 'news') {
      return props.searchItems.length;
    }
  };

  return(
    <React.Fragment>
      {!getWaiting() && getResultsLength() === 0 ?
        <div className="row no-results-row">
          <div className="col-md-10 d-none d-sm-block">&nbsp;</div>
          <div className="col-md-60 mt-3 mt-sm-0">
            No results for - <strong>{props.searchText}</strong>.
          </div>
          <div className="col-md-30" style={{paddingLeft:'.6rem'}}>
            &nbsp;
          </div>
        </div>
        :
        null
      }
    </React.Fragment>
  )
}

const stateToProps=(state)=>{
  return{
    searchText: state.search.searchText,
    waitingSearch: state.search.waitingSearch,
    waitingImageSearch: state.search.waitingImageSearch,
    searchItems: state.search.searchItems,
    searchItemsImage: state.search.searchItemsImage,
    activeTab:state.common.activeTab
  }
};

export default connect(stateToProps,null)(NoResults);
