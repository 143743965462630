import React, {useContext, useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {isMobileOnly} from "react-device-detect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSearch} from "@fortawesome/free-solid-svg-icons";

import ThemeContext from "../../../themeContext";
import Autocomplete from "../../../libs/auto-complete/Autocomplete";
import iconClear from "../../../assets/images/icon_clear.svg";
import {
  AUTO_COMPLETE_CALL,
  CLEAR_IMAGES_DATA,
  IS_AT_MAIN,
  SET_KEEP_AUTO_COMPLETE_OPENED,
  SET_SEARCH_TEXT,
  CLEAR_AUTO_COMPLETE_DATA,
  SET_DOCUMENT_TITLE,
  SET_SEARCH_TEXT_FOR_HIGHLIGHT
} from "../../../constants/actionTypes";
import HighlighterText from "../common/HighlighterText";


function SearchConsoleMobile(props) {
  const [searchText, setSearchText] = useState(props.searchTextStore);
  const [autoCompleteItems, setAutoCompleteItems] = useState([]);
  // Bellow two states are tricks to tackle not to re-call autoComplete api immediately
  // when user press keyup or down on auto-complete menu items
  const [searchTextChangeTime, setSearchTextChangeTime] = useState(null);
  const [reRunAutoCompleteRequired, setReRunAutoCompleteRequired] = useState(false);
  const [showClearButton,setShowClearButton] = useState(false);
  const autoCompleteRef = useRef(null);
  const menuRef = useRef(null);
  const theme = useContext(ThemeContext);

  useEffect(()=>{
    if (autoCompleteRef.current.refs.input){
      autoCompleteRef.current.refs.input.focus();
      setShowClearButton(true);
    }

    function handleTouchstart(event){
      if (autoCompleteRef.current.refs.menu && event.target.type !== 'text'){
        const clearButtonIds = ['sc-clear-link','sc-clear-img',]
        autoCompleteRef.current.refs.input.blur();

        if (!clearButtonIds.includes(event.target.id)){
          setShowClearButton(false);
        }
      }
    }

    document.addEventListener("touchstart", handleTouchstart, false);
    return () => {
      document.removeEventListener("touchstart", handleTouchstart, false);
    };
  },[autoCompleteRef]);

  useEffect(() => {
    setSearchText(props.searchTextStore);
  }, [props.searchTextStore]);

  useEffect(() => {
    if (!searchText) {
      setAutoCompleteItems([]);
      props.clearAutoCompleteData();
    } else {
      getAutocomplete();
    }
  }, [searchTextChangeTime]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAutoCompleteItems(props.autoComplete);
  }, [props.autoComplete]);

  const handleSubmit = (event) => {
    event.preventDefault();
    callSearch(searchText);
  };

  const handleKeyDownAutocomplete = (event) => {
    if (event && event.keyCode === 13) {
      callSearch(searchText);
      event.keyCode = 27;
    }
  };

  const handleFocus = () => {
    if (reRunAutoCompleteRequired) {
      props.setSearchTextForHighlight(searchText);
      setSearchTextChangeTime(new Date());
      setReRunAutoCompleteRequired(false);
    }
  };

  const onKeyDownHandler = (index) => {
    setSearchText(autoCompleteItems[index]);
    props.setSearchTextStore(autoCompleteItems[index]);
    setReRunAutoCompleteRequired(true);
  };

  const onKeyUpHandler = (index) => {
    setSearchText(autoCompleteItems[index]);
    props.setSearchTextStore(autoCompleteItems[index]);
    setReRunAutoCompleteRequired(true);
  };

  const handleChangeAutocomplete = (event) => {
    if (!(event && event.target)) return false;
    const value = event.target.value;

    if (props.keepAutoCompleteOpened) {
      props.setIsBrowserRefreshed(false);
    }

    if (value) {
      setSearchText(event.target.value);
      props.setSearchTextForHighlight(event.target.value);
      props.setSearchTextStore(event.target.value);
      setSearchTextChangeTime(new Date());
    } else {
      setSearchText(value);
      props.setSearchTextStore(value);
      setAutoCompleteItems([]);
      props.clearAutoCompleteData();
    }
  };

  const selectAutocomplete = (value) => {
    setSearchText(value);
    props.setSearchTextStore(value);
    callSearch(value);
  };

  const getAutocomplete = () => {
    if (!searchText) return false;
    props.callAutoCompleteApi(searchText);
  };

  const callSearch = (text) => {
    props.setDocumentTitle(text);
    props.setSearchTextForHighlight(text);

    if (props.isAtMain) {
      props.setIsAtMainTrue();
      props.setIsBrowserRefreshed(true);
      props.history.push(`/search/${text}/all`);
    } else {
      if (props.activeTab === 'all') {
        props.history.push(`/search/${text}/all`);
      } else if (props.activeTab === 'news') {
        props.history.push(`/search/${text}/news`);
      } else if (props.activeTab === 'images') {
        props.clearImageData();
        props.history.push(`/search/${text}/images`);
      }
    }
  };

  const autoCompleteOnClick = () => {
    setShowClearButton(true);
    props.setIsBrowserRefreshed(false);
  };

  const clearSearchInput = (e) => {
    e.preventDefault();
    autoCompleteRef.current.refs.input.focus();
    setSearchText('');
    props.setSearchTextStore('');
    setAutoCompleteItems([]);
    props.clearAutoCompleteData();
  };

  const goBack = () =>{
    props.history.goBack();
  }

  const onMenuArrowClick = (item) =>{
    setSearchText(item + ' ');
    autoCompleteRef.current.refs.input.focus();
    props.callAutoCompleteApi(item);
  }

  return(
    <div className={'search-console-mobile'}>
      <form onSubmit={handleSubmit}>
        <div className="search-container">
          <FontAwesomeIcon icon={faArrowLeft} onClick={goBack} className={'arrow-left'} style={{color: theme.primaryColor}}/>
          <Autocomplete
            ref={autoCompleteRef}
            getItemValue={item => item}
            items={props.autoComplete}
            autoHighlight={false}
            renderInput={(renderInputProps)=>{
              return(
                <div className={'input-container'}>
                  <input
                    type="text"
                    placeholder=""
                    className={'input-search'}
                    autoFocus={!props.isSettingsPage && !isMobileOnly}
                    {...renderInputProps}
                  />
                </div>
              )
            }}
            renderItem={(item, isHighlighted) =>
              <div key={item}
               style={{
                 background: isHighlighted ? '#f2f2f2' : 'white',
                 paddingLeft: '20px',
                 height: '30px',
                 marginTop: '10px',
                 marginBottom: '11px',
                 cursor: 'pointer',
                 fontSize: '1rem',
                 display:"flex",
                 justifyContent:"space-between"
               }}
              >
                <HighlighterText
                  searchWords={props.searchTextForHighlight}
                  textToHighlight={item}
                />
                <div className="icon-arrow">
                  <img alt=""/>
                </div>
              </div>
            }
            renderMenu={(items, value, styles) => {
              return (
                <div
                  style={{
                    ...styles,
                    zIndex: '1',
                    position: 'absolute',
                    top: '59px',
                    left: '0',
                    border: 'none',
                    width: '100vw'
                  }}
                  children={items}
                  ref={menuRef}
                >
                </div>
              );
            }
            }
            inputProps={{
              onKeyDown: handleKeyDownAutocomplete,
              onFocus: handleFocus,
              onClick: autoCompleteOnClick
            }}
            onChange={handleChangeAutocomplete}
            onSelect={selectAutocomplete}
            onMenuArrowClick={onMenuArrowClick}
            value={searchText}
            onKeyDownHandler={onKeyDownHandler}
            onKeyUpHandler={onKeyUpHandler}
          />

          <a href="/" id="sc-clear-link" className="btn-clear" onClick={(event) => clearSearchInput(event)}>
            {searchText && showClearButton &&
              <img id="sc-clear-img" src={iconClear} alt={''}/>
            }
          </a>

          <button className="btn-search" type="submit" disabled={!searchText} style={{color: theme.primaryColor}}>
            <FontAwesomeIcon icon={faSearch}/>
          </button>
        </div>
      </form>
      <hr/>
    </div>
  )
}

const stateToProps=(state)=>{
  return{
    activeTab: state.common.activeTab,
    autoComplete:state.search.autoComplete,
    isAtMain: state.common.isAtMain,
    searchTextStore: state.search.searchText,
    searchTextForHighlight: state.search.searchTextForHighlight,
    keepAutoCompleteOpened: state.common.keepAutoCompleteOpened
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    setSearchTextStore: (searchText) => dispatch({type:SET_SEARCH_TEXT,value:searchText}),
    setSearchTextForHighlight: (searchText) => dispatch({type:SET_SEARCH_TEXT_FOR_HIGHLIGHT,value:searchText}),
    clearImageData: () => dispatch({type:CLEAR_IMAGES_DATA}),
    callAutoCompleteApi: (text) => dispatch({ type: AUTO_COMPLETE_CALL, value: text }),
    setIsAtMainTrue: ()=>dispatch({type:IS_AT_MAIN,value:true}),
    setIsBrowserRefreshed: (val) => dispatch({type:SET_KEEP_AUTO_COMPLETE_OPENED, value: val}),
    clearAutoCompleteData: () => dispatch({type:CLEAR_AUTO_COMPLETE_DATA}),
    setDocumentTitle: (val) =>dispatch({type:SET_DOCUMENT_TITLE,value:val})
  }
};

export default withRouter(connect(stateToProps,dispatchToProps)(SearchConsoleMobile));
