import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {useHistory,withRouter} from "react-router-dom";
import { toast } from 'react-toastify';
import {isMobileOnly,isTablet,withOrientationChange} from "react-device-detect";

import {
  IS_AT_MAIN,
  NEWS_SEARCH_CALL,
  WEB_SEARCH_CALL,
  SET_ACTIVE_TAB,
  SET_CURRENT_PAGE,
  SET_DOCUMENT_TITLE,
  SET_RELATED_SEARCH_TEXT,
  SET_SEARCH_TEXT,
  CLEAR_IMAGES_DATA, CHANGE_RELATED_SEARCH_TEXT
} from "../../../constants/actionTypes";
import SearchInput from "../SearchInput";
import TabsBing from "../../tabs/bing";
import SearchWeb from "../SearchWeb";
import SearchNews from "../SearchNews";
import ArtGallery from "../ArtGallery";
import NoResults from "../NoResults";
import RelatedSearch from "../RelatedSearch";
import CustomPagination from "../common/CustomPagination";


function SearchComponent(props) {
  const history = useHistory();

  const {totalCount,pageSize} = props;
  const pageCount = Math.ceil(totalCount/pageSize);

  const {totalCountNews,pageSizeNews} = props;
  const pageCountNews = Math.ceil(totalCountNews/pageSizeNews);

  if (props.isAtMain){
    const text = props.match.params.text;
    const relatedText = props.match.params.related_text;

    props.setSearchTextStore(text);
    props.setCurrentPageStore(1);

    if (relatedText != null && relatedText !== '') {
      props.setRelatedSearchTextStore(relatedText);
      props.setDocumentTitle(relatedText);
    }
    else {
      props.setRelatedSearchTextStore('');
      props.setDocumentTitle(text);
    }

    props.setIsAtMainFalse();
  }

  useEffect(()=>{
    let activeTab = props.match.params.tab_name;
    let pageNumber = props.match.params.page_number;

    if (!activeTab){
      activeTab = 'all';
    }

    if (!pageNumber){
      pageNumber = 1;
    }

    props.setActiveTab(activeTab);
    props.setSearchTextStore(props.match.params.text);

    if (props.match.params.related_text){
      props.changeRelatedSearchText(props.match.params.related_text);
    }

    if (activeTab === 'all') {
      props.callSearchApi(pageNumber);
    } else if (activeTab === 'news') {
      props.callNewsSearchApi(pageNumber);
    } else if (activeTab === 'images') {
      props.clearImageData();
    }
  },[props.match.params.text,props.match.params.tab_name,props.match.params.related_text,props.match.params.page_number]);

  useEffect(()=>{
    if (!props.waitingSearch && !props.searchSuccess && props.searchError){
      toast.error('There have an error in server end! Error:' + props.searchError,{
        position: toast.POSITION.TOP_RIGHT,
        draggable: true
      })
    }
  },[props.waitingSearch,props.searchSuccess,props.searchError]); // eslint-disable-line react-hooks/exhaustive-deps

  const onPageChanged = (data) =>{
    const {selected} = data;
    const text = props.match.params.text;
    const relatedSearch = props.match.params.related_text;

    if (relatedSearch){
      history.push(`/search/${text}/${props.activeTab}/related/${relatedSearch}/${selected+1}`);
    } else{
      history.push(`/search/${text}/${props.activeTab}/${selected+1}`);
    }
  };

  return (
    <React.Fragment>
      <SearchInput isSettingsPage={false}/>
      <TabsBing/>
      <div className="content">
        {(props.activeTab === 'all' || props.activeTab === 'news') && !isMobileOnly &&
          <div className="left-side-blank"/>
        }

        {(props.activeTab === 'all' || props.activeTab === 'news') &&
          < NoResults />
        }

        {(props.activeTab === 'all') &&
          <SearchWeb/>
        }

        {(props.activeTab ==='news') &&
          <SearchNews/>
        }

        {(props.activeTab ==='images') &&
          <ArtGallery/>
        }
      </div>

      {(isMobileOnly || (isTablet && props.isPortrait)) && props.activeTab === 'all' &&
        <RelatedSearch isMobile={true}/>
      }

      {(props.activeTab === 'all') &&
        <CustomPagination
          waitingSearch={props.waitingSearch}
          totalCount={props.totalCount}
          pageCount={pageCount}
          currentPage={(props.currentPage - 1)}
          paginationCtrlMaxSize={props.paginationCtrlMaxSize}
          onPageChange={onPageChanged}
        />
      }

      {(props.activeTab === 'news') &&
        <CustomPagination
          waitingSearch={props.waitingSearchNews}
          totalCount={props.totalCountNews}
          pageCount={pageCountNews}
          currentPage={(props.currentPageNews-1)}
          paginationCtrlMaxSize={props.paginationCtrlMaxSize}
          onPageChange={onPageChanged}
        />
      }

      {!isMobileOnly &&
        <div className="bottom-blank"/>
      }
    </React.Fragment>
  )
}

const stateToProps=(state)=>{
  return{
    searchTextStore: state.search.searchText,
    waitingSearch: state.search.waitingSearch,
    searchSuccess: state.search.searchSuccess,
    searchError: state.search.searchError,
    currentPage:state.search.currentPage,
    totalCount:state.search.totalCount,
    pageSize:state.search.pageSize,

    waitingSearchNews: state.search.waitingSearchNews,
    searchSuccessNews:state.search.searchSuccessNews,
    searchErrorNews:state.search.searchErrorNews,
    currentPageNews:state.search.currentPageNews,
    totalCountNews:state.search.totalCountNews,
    pageSizeNews:state.search.pageSizeNews,

    isAtMain: state.common.isAtMain,
    activeTab:state.common.activeTab,
    paginationCtrlMaxSize: state.search.paginationCtrlMaxSize
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    setSearchTextStore: (searchText) => dispatch({type:SET_SEARCH_TEXT,value:searchText}),
    setRelatedSearchTextStore: (relatedSearchText) => dispatch({type:SET_RELATED_SEARCH_TEXT,value:relatedSearchText}),
    setCurrentPageStore: (currentPage) => dispatch({type:SET_CURRENT_PAGE,value:currentPage}),
    callSearchApi: (pageNumber) => dispatch({type:WEB_SEARCH_CALL,value:{pageNumber}}),
    callNewsSearchApi:(pageNumber)=> dispatch({type:NEWS_SEARCH_CALL,value:{pageNumber}}),
    setIsAtMainFalse: ()=>dispatch({type:IS_AT_MAIN,value:false}),
    setDocumentTitle: (val) =>dispatch({type:SET_DOCUMENT_TITLE,value:val}),
    setActiveTab: (val)=>dispatch({type:SET_ACTIVE_TAB,value:val}),
    clearImageData: () => dispatch({type:CLEAR_IMAGES_DATA}),
    changeRelatedSearchText: (relatedSearchText) => dispatch({type:CHANGE_RELATED_SEARCH_TEXT, value:relatedSearchText})
  }
};

export default withOrientationChange(withRouter(connect(stateToProps,dispatchToProps)(SearchComponent)));
