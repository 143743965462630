import React, {useContext, useEffect, useState} from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {capitalizeFirstLetter} from "../../../libs/common";
import {IGNORE_IMAGE_GALLERY_API_CALL, SET_IS_FOOTER_REQUIRED} from "../../../constants/actionTypes";
import ThemeContext from "../../../themeContext";

const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLEllipsis);

function ImageDetailMobile(props) {
  const windowWidth = window.innerWidth;
  const [loading, setLoading] = useState(true);
  const theme = useContext(ThemeContext);
  const history = useHistory();

  const item = props.item;
  const imageDivHeight = (windowWidth / item.imageWidth) * item.imageHeight;

  useEffect(()=>{
    props.setIsFooterRequired(false);
    return ()=>{
      props.setIsFooterRequired(true);
    }
  },[])

  // useCallback(() => {
  //   if (props.isVisible){
  //     history.push('/image_detail_mobile/' + btoa(props.item.imageUrl));
  //   }
  // }, [props.isVisible]);

  const goBack = (event) => {
    event.preventDefault();
    history.goBack();
  }

  const imageLoaded = () => {
    setLoading(false);
  }

  props.setIgnoreImageGalleryApiCall(true);

  return (
    <div className="image-detail-mobile">
      <React.Fragment>
        <div style={{position: "relative", height: imageDivHeight, borderBottom:"1px solid #d8d8d8"}}>
          <a href='/' onClick={goBack} className='cross'>
            <FontAwesomeIcon icon={faTimes} color={'#fff'}/>
          </a>
          {loading &&
            <img src={item.imageBase64} className="image thumbnail" alt=""/>
          }
          <img src={item.imageUrl} onLoad={imageLoaded} className="image original" alt=""/>
        </div>
      </React.Fragment>
      <div className="details">
        <div className="left">
          <div className="domain">
            {item.favIconBase64 &&
              <img className="favicon" src={item.favIconBase64} alt=""/>
            }
            <span className="text">
              {capitalizeFirstLetter(item.domainName)}
            </span>
          </div>
          <span className="description">
            <ResponsiveHTMLEllipsis
              unsafeHTML={item.imageTitle}
              maxLine='2'
              ellipsis='...'
              basedOn='words'
            />
          </span>
        </div>
        <a href={item.webpageUrl} target="_blank" style={{backgroundColor: theme.primaryColor}}>
          Visit
        </a>
      </div>
    </div>
  )
}

const dispatchToProps = (dispatch) =>{
  return {
    setIgnoreImageGalleryApiCall: (val)=> dispatch({type:IGNORE_IMAGE_GALLERY_API_CALL, value: val}),
    setIsFooterRequired: (val) => dispatch({type:SET_IS_FOOTER_REQUIRED, value:val})
  }
}

export default connect(null,dispatchToProps)(ImageDetailMobile);
