import React, {useEffect, useState, useRef} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

import Autocomplete from "../../libs/auto-complete/Autocomplete";
import iconClear from "../../assets/images/icon_clear.svg";
import {callAutoCompleteApi, callImageSearchApi} from "../../actions/search";
import {callSearchApi,callNewsSearchApi} from "../../actions/search";
import {IS_AT_MAIN, SET_SEARCH_TEXT, SET_KEEP_AUTO_COMPLETE_OPENED} from "../../constants/actionTypes";

function SearchConsole(props) {
  const [searchText,setSearchText] = useState(props.searchTextStore);
  const [autoCompleteItems,setAutoCompleteItems] =useState([]);
  // Bellow two states are tricks to tackle not to re-call autoComplete api immediately
  // when user press keyup or down on auto-complete menu items
  const [searchTextChangeTime,setSearchTextChangeTime] = useState(null);
  const [reRunAutoCompleteRequired, setReRunAutoCompleteRequired] =useState(false);
  const autoCompleteRef =useRef(null);

  useEffect(()=>{
    setSearchText(props.searchTextStore);
  },[props.searchTextStore]);

  useEffect(()=>{
    if (!searchText){
      setAutoCompleteItems([]);
    } else{
      getAutocomplete();
    }
  },[searchTextChangeTime]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    setAutoCompleteItems(props.autoComplete);
  },[props.autoComplete]);

  const handleSubmit = (event) =>{
    event.preventDefault();
    callSearch(searchText);
  };

  const handleKeyDownAutocomplete = (event) =>{
    if (event && event.keyCode === 13) {
      callSearch(searchText);
      event.keyCode = 27;
    }
  };

  const handleFocus = () =>{
    if (reRunAutoCompleteRequired){
      setSearchTextChangeTime(new Date());
      setReRunAutoCompleteRequired(false);
    }
  };

  const onKeyDownHandler = (index) =>{
    setSearchText(autoCompleteItems[index]);
    props.setSearchTextStore(autoCompleteItems[index]);
    setReRunAutoCompleteRequired(true);
  };

  const onKeyUpHandler = (index) =>{
    setSearchText(autoCompleteItems[index]);
    props.setSearchTextStore(autoCompleteItems[index]);
    setReRunAutoCompleteRequired(true);
  };

  const handleChangeAutocomplete = (event) => {
    if (!(event && event.target)) return false;
    const value = event.target.value;

    if (props.keepAutoCompleteOpened){
      props.setIsBrowserRefreshed(false);
    }

    if (value) {
      setSearchText(event.target.value);
      props.setSearchTextStore(event.target.value);
      setSearchTextChangeTime(new Date());
    } else {
      setSearchText(value);
      props.setSearchTextStore(value);
      setAutoCompleteItems([]);
    }
  };

  const selectAutocomplete = (value) => {
    setSearchText(value);
    props.setSearchTextStore(value);
    callSearch(value);
  };

  const getAutocomplete = () => {
    if (!searchText) return false;
    props.callAutoCompleteApi(searchText);
  };

  const callSearch = (text) =>{
    if (props.isAtMain){
      props.setIsAtMainTrue();
      props.setIsBrowserRefreshed(true);
      props.history.push('/search/'+text);
    } else{
      if (props.activeTab === 'all'){
        props.callSearchApi(1);
        props.history.push(`/search/${text}`);
      } else if(props.activeTab === 'news'){
        props.callNewsSearchApi(1);
        props.history.push(`/search/${text}/news`);
      } else if(props.activeTab === 'images'){
        props.callImageSearchApi(1);
        props.history.push(`/search/${text}/images`);
      }
    }
  };

  const autoCompleteOnClick = () =>{
    props.setIsBrowserRefreshed(false);
  };

  const clearSearchInput = (e) =>{
    e.preventDefault();
    autoCompleteRef.current.refs.input.focus();

    setSearchText('');
    props.setSearchTextStore('');
  };

  return(
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <div className={'input-group'+(!props.isAtMain? ' search-input-group': '')}>
          <Autocomplete
            ref={autoCompleteRef}
            getItemValue={item => item}
            items={props.autoComplete}
            autoHighlight={false}
            renderInput={(props) => {
              return (
                <input
                  type="text"
                  placeholder="Contextual Web Search"
                  className={'form-control search-input'}
                  autoFocus
                  {...props}
                />
              )
            }
            }
            renderItem={(item, isHighlighted) =>
              <div key={item}
                   style={{
                     background: isHighlighted ? '#f2f2f2' : 'white',
                     paddingLeft: '20px',
                     height: '30px',
                     paddingTop:'3px',
                     cursor:'pointer',
                     fontSize:'1rem'
                   }}
              >
                {item}
              </div>
            }
            renderMenu={(items, value, styles) => {
              if (searchText && !props.keepAutoCompleteOpened) {
                return (
                  <div
                    style={{
                      ...styles,
                      zIndex: '1',
                      position: 'absolute',
                      top: '40px',
                      left: '0',
                      border: '1px solid rgba(0,0,0,.15)',
                    }}
                    children={items}>
                  </div>
                );
              } else {
                return <div/>;
              }
            }
            }
            inputProps={{
              onKeyDown: handleKeyDownAutocomplete,
              onFocus: handleFocus,
              onClick:autoCompleteOnClick
            }}
            onChange={handleChangeAutocomplete}
            onSelect={selectAutocomplete}
            value={searchText}
            onKeyDownHandler={onKeyDownHandler}
            onKeyUpHandler={onKeyUpHandler}
          />

          {searchText &&
            <a href="/" className="btn btn-input-cross" onClick={(event)=>clearSearchInput(event)}>
              <img src={iconClear} alt={''}/>
            </a>
          }
          <span className="input-group-btn">
            <button className="btn btn-primary" type="submit" disabled={!searchText}>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </span>
        </div>
      </div>
    </form>
  )
}

const stateToProps=(state)=>{
  return{
    activeTab: state.common.activeTab,
    autoComplete:state.search.autoComplete,
    isAtMain: state.common.isAtMain,
    searchTextStore: state.search.searchText,
    keepAutoCompleteOpened: state.common.keepAutoCompleteOpened
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    setSearchTextStore: (searchText) => dispatch({type:SET_SEARCH_TEXT,value:searchText}),
    callSearchApi: (pageNumber) => dispatch(callSearchApi(pageNumber)),
    callNewsSearchApi: (pageNumber) => dispatch(callNewsSearchApi(pageNumber)),
    callImageSearchApi: (pageNumber) => dispatch(callImageSearchApi(pageNumber)),
    callAutoCompleteApi: (text) => dispatch(callAutoCompleteApi(text)),
    setIsAtMainTrue: ()=>dispatch({type:IS_AT_MAIN,value:true}),
    setIsBrowserRefreshed: (val) => dispatch({type:SET_KEEP_AUTO_COMPLETE_OPENED, value: val})
  }
};

export default withRouter(connect(stateToProps,dispatchToProps)(SearchConsole));
