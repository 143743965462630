import React, {useState, useLayoutEffect} from 'react';
import {connect} from "react-redux";

import {SET_WINDOW_RESIZE_DATE, SET_WINDOW_INNER_WIDTH} from "../../constants/actionTypes";
import SearchComponent from "../Search";

// Custom Hook to get window width when browser is resized
function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function SearchContainer(props) {
  const [windowWidth] = useWindowSize();
  props.setWindowInnerWidth(windowWidth);
  console.log('Search Container executed...');

  if (windowWidth < 415) {  //mobile devices (smaller screen)
    props.setWindowResizeData({
      imageItemsPerRow: 2,
      pageSizeImage: 8,
      pageSizeImageInitial: 8,
      imageColumClassName: 'col-50',
      paginationCtrlMaxSize:1
    });
  }
  else if (windowWidth >= 415 && windowWidth < 576) {  //mobile devices (bigger screen)
    props.setWindowResizeData({
      imageItemsPerRow:3,
      pageSizeImage: 14,
      pageSizeImageInitial: 14,
      imageColumClassName: 'col-33',
      paginationCtrlMaxSize:4,
    });
  }
  else if (windowWidth >= 576 && windowWidth < 768) { //sm devices
    props.setWindowResizeData({
      imageItemsPerRow: 3,
      pageSizeImage: 14,
      pageSizeImageInitial: 14,
      paginationCtrlMaxSize:4
    });
  }
  else if (windowWidth >= 768 && windowWidth < 992) { //md devices
    props.setWindowResizeData({
      imageItemsPerRow: 5,
      pageSizeImage: 16,
      pageSizeImageInitial: 20,
      paginationCtrlMaxSize:4
    });
  }
  else if (windowWidth >= 992 && windowWidth < 1200) {  //lg devices
    props.setWindowResizeData({
      imageItemsPerRow: 6,
      pageSizeImage: 20,
      pageSizeImageInitial: 30,
      paginationCtrlMaxSize:4
    });
  }
  else if (windowWidth >= 1200) { //xl devices
    props.setWindowResizeData({
      imageItemsPerRow: 8,
      pageSizeImage: 20,
      pageSizeImageInitial: 35,
      paginationCtrlMaxSize:4
    });
  }

  return (
    <div className="container-fluid">
      <div className="row" style={{backgroundColor:'#fafafa',height:'1.2rem'}}>
        <div className="col-100">&nbsp;</div>
      </div>
      <SearchComponent/>
    </div>
  )
}

const dispatchToProps=(dispatch)=>{
  return{
    setWindowInnerWidth:(val)=>dispatch({type:SET_WINDOW_INNER_WIDTH, value:val}),
    setWindowResizeData:(obj)=>dispatch({type:SET_WINDOW_RESIZE_DATE, value:obj}),
  }
};

export default connect(null,dispatchToProps)(SearchContainer);
