import React from 'react';
import * as settings from "../../constants/config";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any child components and re-render with error message
    this.setState({error});
  }

  render() {
    if (this.state.error && settings.ENV !== 'development') {
      return (
        <div style={{'textAlign': 'center', 'margin': '10px auto'}}>
          <p>There was an issue.</p>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
