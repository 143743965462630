import {
  SEARCH_WAITING_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FAILED,
  NEWS_WAITING_REQUEST,
  NEWS_SUCCESS,
  NEWS_FAILED,
  AUTO_COMPLETE_WAITING,
  AUTO_COMPLETE_SUCCESS,
  AUTO_COMPLETE_FAILED,
  SET_SEARCH_TEXT,
  SET_RELATED_SEARCH_TEXT,
  SET_CURRENT_PAGE,
  CLEAR_DID_U_MEAN,
  CLEAR_SEARCH_DATA,
  CLEAR_NEWS_DATA,
  SET_RELATED_SEARCH_TO_SEARCH_TEXT,
  CHANGE_RELATED_SEARCH_TEXT,
  CLEAR_IMAGES_DATA,
  IMAGES_SUCCESS,
  IMAGES_WAITING_REQUEST,
  IMAGES_FAILED,
  SET_WINDOW_RESIZE_DATE,
  CLEAR_AUTO_COMPLETE_DATA,
  SET_FLOATING_SEARCH_ICON_VISIBLE,
  IGNORE_IMAGE_GALLERY_API_CALL, SET_SEARCH_TEXT_FOR_HIGHLIGHT, SET_RUN_AUTOCOMPLETE
} from "../constants/actionTypes";

const initialState={
  searchText:'',
  searchTextForHighlight:'',
  searchedText:'',
  relatedSearchText:'',
  currentPage:1,
  pageSize:10,
  searchTextHasQuote:false,
  isFloatingSearchIconVisible:false,
  runAutocomplete: true,

  searchItems:[],
  relatedSearches:[],
  queryParsing:'',
  relatedDomainRows:[],
  relatedQueryRows:[],
  responseTime:'',
  totalCount:0,
  didUMean:'',
  waitingSearch:false,
  searchSuccess:false,
  searchError:null,

  currentPageNews:1,
  pageSizeNews:10,
  searchItemsNews:[],
  relatedSearchesNews:[],
  responseTimeNews:'',
  totalCountNews:0,
  didUMeanNews:'',
  waitingSearchNews:false,
  searchSuccessNews:false,
  searchErrorNews:null,

  waitingAutocomplete:false,
  autoComplete:[],
  autoCompleteError:null,

  waitingImageSearch:false,
  pageSizeImage: 20,
  pageSizeImageInitial : 35,
  currentPageImage : 0,
  searchItemsImage: [],
  imageItemsDisplay: [],
  imageItemsPerRow: 8,
  imageSearchAtEnd: false,
  imageColumClassName: 'col-50',
  imagesItemsChanged:'',
  imageDisplayRows:[],
  imageSearchSuccess:false,
  imageSearchError:null,
  paginationCtrlMaxSize:0,
  ignoreImageGalleryApiCall:false
};

export default (state=initialState,action)=>{
  switch (action.type){
    case SET_WINDOW_RESIZE_DATE:{
      return {...state, ...action.value};
    }

    case SET_SEARCH_TEXT:{
      return {...state, searchText: action.value}
    }
    case SET_SEARCH_TEXT_FOR_HIGHLIGHT:{
      return {...state, searchTextForHighlight: action.value}
    }
    case SET_RELATED_SEARCH_TEXT:{
      return {...state, relatedSearchText: action.value}
    }
    case SET_CURRENT_PAGE:{
      return {...state, currentPage: action.value}
    }

    case CLEAR_DID_U_MEAN:{
      return {
        ...state,
        didUMean: '',
        didUMeanNews: ''
      }
    }
    case CLEAR_SEARCH_DATA:{
      return {
        ...state,
        currentPage:1,
        searchItems: [],
        relatedSearches: [],
        relatedQueryRows: [],
        relatedDomainRows: [],
        responseTime: '',
        didUMean: '',
        totalCount: 0
      }
    }
    case CLEAR_NEWS_DATA:{
      return {
        ...state,
        currentPageNews:1,
        searchItemsNews: [],
        relatedSearchesNews: [],
        responseTimeNews: '',
        didUMeanNews: '',
        totalCountNews: 0
      }
    }

    case SEARCH_WAITING_REQUEST:{
      return {...state,waitingSearch:action.value};
    }
    case SEARCH_SUCCESS:{
      const response = action.value;
      const items = response.contents.map(item => {
        return {...item, publishedTime: item.publishedTime};
      });

      return {...state,
        searchSuccess: true,
        searchError: null,
        searchedText: response.searchedText,
        didUMean: response.didUMean,
        searchItems: items,
        relatedSearches: response.relatedSearch,
        queryParsing: response.queryParsing,
        relatedDomainRows: response.relatedDomainRows,
        relatedQueryRows: response.relatedQueryRows,
        currentPage: response.currentPage,
        totalCount: response.totalCount,
        responseTime: response.responseTime
      };
    }
    case SEARCH_FAILED:{
      return {...state,
        searchSuccess:false,
        searchError:action.value
      };
    }

    case NEWS_WAITING_REQUEST:{
      return {...state,waitingSearchNews:action.value};
    }
    case NEWS_SUCCESS:{
      const response = action.value;
      const items = response.contents.map(item => {
        return {...item, publishedTime: item.publishedTime};
      });

      return {...state,
        searchSuccessNews: true,
        searchErrorNews: null,
        searchedText: response.searchedText,
        didUMeanNews: response.didUMean,
        searchItemsNews: items,
        relatedSearchesNews: response.relatedSearch,
        currentPageNews: response.currentPage,
        totalCountNews: response.totalCount,
        responseTimeNews: response.responseTime
      };
    }
    case NEWS_FAILED:{
      return {...state,
        searchSuccessNews:false,
        searchErrorNews:action.value
      };
    }

    case SET_RELATED_SEARCH_TO_SEARCH_TEXT:{
      return {
        ...state,
        searchText: action.value,
        relatedSearchText: ''
      }
    }
    case CHANGE_RELATED_SEARCH_TEXT:{
      return {
        ...state,
        relatedSearchText: action.value,
      }
    }

    case CLEAR_IMAGES_DATA:{
      return {
        ...state,
        currentPageImage: 0,
        searchItemsImage:[],
        imageItemsDisplay: [],
        imageDisplayRows: [],
        imageSearchAtEnd: false
      }
    }

    case IMAGES_WAITING_REQUEST:{
      return {...state,waitingImageSearch:action.value};
    }
    case IMAGES_SUCCESS:{
      const {searchedText,images,currentPage} =action.value;
      let isImageSearchAtEnd = false;
      let imagesItemsChanged = '';

      if (images.length ===0){
        isImageSearchAtEnd = true;
      }

      if (currentPage === 1){
        imagesItemsChanged = 'first_new_data';
      } else{
        imagesItemsChanged = 'new_data';
      }

      return {
        ...state,
        imageSearchSuccess:true,
        imageSearchError:null,
        searchedText: searchedText,
        searchItemsImage:[...state.searchItemsImage, ...images],
        imageItemsDisplay:[...state.imageItemsDisplay, ...images],
        imageSearchAtEnd:isImageSearchAtEnd,
        imagesItemsChanged: imagesItemsChanged,
        currentPageImage: currentPage
      }
    }
    case IMAGES_FAILED:{
      return {...state,
        imageSearchSuccess:false,
        imageSearchError:action.value,
        imageSearchAtEnd:true
      };
    }

    case AUTO_COMPLETE_WAITING:{
      return {...state,waitingAutocomplete:action.value};
    }
    case AUTO_COMPLETE_SUCCESS:{
      return {...state,autoComplete:action.value.data};
    }
    case AUTO_COMPLETE_FAILED:{
      return {...state,searchError:action.value};
    }
    case CLEAR_AUTO_COMPLETE_DATA:{
      return {...state,autoComplete: []}
    }
    case SET_RUN_AUTOCOMPLETE:{
      return {...state,runAutocomplete: action.value}
    }

    case SET_FLOATING_SEARCH_ICON_VISIBLE:{
      return {...state,isFloatingSearchIconVisible:action.value}
    }

    case IGNORE_IMAGE_GALLERY_API_CALL:{
      return {...state,ignoreImageGalleryApiCall: action.value}
    }

    default:
      return state;
  }
}
