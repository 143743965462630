import React from 'react';
import {connect} from "react-redux";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import {isMobileOnly} from "react-device-detect";

import SearchItemImage from "../../Common/SearchItemImage";
import CustomTimeAgo from "../../Common/CustomTimeAgo";
import {capitalizeFirstLetter} from "../../../libs/common";

const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLEllipsis);

function NewsItem(props) {
  const {item,windowInnerWidth} = props;
  const isPublishTimeAgoActive=true;

  let paddingLeft = '8px';
  const hasNotImage = (item.imageBase64 ==='' && item.imageUrl === '');
  const hasImage = (item.imageBase64 !=='' || item.imageUrl !=='');

  if (hasNotImage){
    paddingLeft = '15px';
  }

  const truncatedDomain = ()=> {
    let truncateVal;

    if (item.imageBase64 === '' && item.imageUrl === '') {
      truncateVal = parseInt((windowInnerWidth * 0.048).toString());
    }
    else {
      truncateVal = parseInt((windowInnerWidth * 0.038).toString());
    }

    return item.domainName.length > truncateVal ? item.domainName.substring(0, truncateVal) + "..." : item.domainName;
  };

  const gotoUrl = (url) =>{
    window.location.href = url;
  }

  const renderBrowserView = () => {
    return (
      <a href={item.url} className="news-row">
        <div className="text-content">
          <div className="domain">
            {item.favIconBase64 &&
              <img src={item.favIconBase64} alt=""/>
            }
            <span>
              {capitalizeFirstLetter(truncatedDomain())}
            </span>
          </div>

          <span className="title">
            <ResponsiveHTMLEllipsis
              unsafeHTML={item.title}
              maxLine='2'
              ellipsis='...'
              basedOn='words'
            />
          </span>

          <div className="description">
            <ResponsiveHTMLEllipsis
              unsafeHTML={item.description}
              maxLine='2'
              ellipsis='...'
              basedOn='words'
            />
          </div>

          {item.publishedTime && isPublishTimeAgoActive &&
            <span className="time">
              <CustomTimeAgo date={item.publishedTime} includePreFix={false} includePostFix={false}/>
            </span>
          }
        </div>
        <div className="image">
          {item.imageBase64 !== '' &&
            <SearchItemImage imgSrc={item.imageBase64} classNames="news-image"/>
          }
        </div>
      </a>
    )
  }

  const renderMobileView = () => {
    return (
      <div onClick={() => gotoUrl(item.url)} className="news-row-mobile">
        <div className="description">
          <div className="domain">
            {item.favIconBase64 &&
              <img src={item.favIconBase64} alt=""/>
            }
            <span>
              {capitalizeFirstLetter(truncatedDomain())}
            </span>
          </div>

          <span className="title">
            <ResponsiveHTMLEllipsis
              unsafeHTML={item.title}
              maxLine='3'
              ellipsis='...'
              basedOn='words'
            />
          </span>
          {item.publishedTime && isPublishTimeAgoActive &&
            <span className="time">
              <CustomTimeAgo date={item.publishedTime} includePreFix={false} includePostFix={false}/>
            </span>
          }
        </div>
        <div className="image">
          {item.imageBase64 !== '' &&
            <SearchItemImage imgSrc={item.imageBase64} classNames="news-image"/>
          }
        </div>
      </div>
    )
  }

  return(
    <>
      {isMobileOnly ?
        renderMobileView()
        :
        renderBrowserView()
      }
    </>
  )
}

const stateToProps=(state)=>{
  return{
    windowInnerWidth:state.common.windowInnerWidth,
  }
};

export default connect(stateToProps,null)(NewsItem);
