import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import  { Router, Route, Switch } from 'react-router';

import { store, persistor,history } from "./store";
import App from './App';
import ErrorBoundary from "./components/Common/ErrorBoundary";
import * as serviceWorker from './serviceWorker';

import './assets/SCSS/bootstrap_custom.scss';
import 'react-toastify/dist/ReactToastify.css';
import './assets/SCSS/styles.scss';
import './assets/SCSS/bing.scss';
import './assets/SCSS/material_customize.scss';

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router
          onUpdate={() => window.scrollTo(0, 0)}
          history={history}
        >
          <Switch>
            <Route
              path="/"
              component={App}
            />
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
