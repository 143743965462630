import React from "react";
import Highlighter from "react-highlight-words";


function HighlighterText (props){
  return(
    <Highlighter
      highlightTag="span"
      unhighlightClassName='unhighlight'
      // Escape all special chars (i.e. ?,^,"). Details bellow here in stackoverflow
      // https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
      // https://stackoverflow.com/questions/3561493/is-there-a-regexp-escape-function-in-javascript
      searchWords={props.searchWords.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').split(' ')}
      textToHighlight={props.textToHighlight}
    />
  )
}

export default HighlighterText;
