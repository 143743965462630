import React from 'react';
import {isMobile} from "react-device-detect";

import SearchListWeb from "../SearchListWeb";
import QueryAnalysis from "../QueryAnalysis";


function SearchWeb(props) {
  return(
    <React.Fragment>
      <SearchListWeb/>
      {!isMobile &&
        <QueryAnalysis/>
      }
    </React.Fragment>
  )
}

export default SearchWeb;
