import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';

import common from './reducers/common';
import search from './reducers/search';
import settings from './reducers/settings';

const appReducer = combineReducers({
  common,
  search,
  settings,
  router:routerReducer,
});

const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export default rootReducer;
