import React from "react";
import MultiClamp from '../../libs/react-multi-clamp';
import SearchItemImage from "../Common/SearchItemImage";
import CustomTimeAgo from "../Common/CustomTimeAgo";


function SearchItem(props) {
  const {item} = props;
  const isPublishTimeAgoActive=true;

  return (
    <React.Fragment>
      <div className="d-none d-sm-block all-item-row" style={{paddingLeft: "2px", paddingRight: "2px"}}>
        <div className="row mb-4" style={{marginRight: '23px'}}>
          <div className="col-sm-13 pr-0">
            {item.imageBase64 !== '' &&
              <a href={item.imageUrl}>
                <SearchItemImage imgSrc={item.imageBase64} classNames="search-image" alt=''/>
              </a>
            }
          </div>
          <div className={(item.imageBase64 === '' && item.imageUrl === '' ? 'col-sm-100 pl-3' : '') +
          (item.imageBase64 !== '' || item.imageUrl !== '' ? ' col-sm-87 pl-2 with-image-column' : '')}>
            <div className="row" style={{marginTop: '2px', height: '24px', marginBottom: '-1px'}}>
              <div className="search-title-column">
                <a href={item.url} className="search-title">
                  <MultiClamp ellipsis="..." clamp={1}>
                    <span dangerouslySetInnerHTML={{__html: item.title}}/>
                  </MultiClamp>
                </a>
              </div>
            </div>
            <div className="row" style={{height:'21px'}}>
              <div className="col-100">
                <MultiClamp ellipsis="..." clamp={1}>
                  <a className="search-uri" href='/' onClick={(e)=>e.preventDefault()}>
                    <span dangerouslySetInnerHTML={{__html: item.url}}/>
                  </a>
                </MultiClamp>
              </div>
            </div>
            <div className="row">
              <MultiClamp ellipsis="..." clamp={2}>
                <div className="col-100 search-description">
                  {isPublishTimeAgoActive &&
                    <span className="time-ago">
                      <CustomTimeAgo date={item.publishedTime} includePreFix={false} includePostFix={true}/>
                    </span>
                  }
                  <span dangerouslySetInnerHTML={{__html:item.description}}/>
                </div>
              </MultiClamp>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid d-block d-sm-none p-2 mb-2 mobile-content">
        <div className="row" style={{marginTop:'2px'}}>
          <div className="col-100">
            <a href={item.url} className="search-title">
              <MultiClamp ellipsis="..." clamp={2}>
                <span dangerouslySetInnerHTML={{__html: item.title}}/>
              </MultiClamp>
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-100">
            <MultiClamp ellipsis="..." clamp={1}>
              <a href={item.url} className="search-uri">
                <span dangerouslySetInnerHTML={{__html: item.url}}/>
              </a>
            </MultiClamp>
            {item.publishedTime && isPublishTimeAgoActive &&
              <span style={{color:'#808080',fontSize:'15px'}}>
                <CustomTimeAgo date={item.publishedTime} includePreFix={true} includePostFix={false}/>
              </span>
            }
          </div>
        </div>
        <hr className="content-divider"/>
        <div className="row">
          <div className="col-30 pr-0">
            {item.imageBase64 !== '' &&
              <SearchItemImage imgSrc={item.imageBase64} classNames="search-image mt-1" alt=''/>
            }
          </div>
          <div className={'search-description ' + (item.imageBase64 === '' && item.imageUrl === ''?'col-100 ':'') + (item.imageBase64 !== '' || item.imageUrl !== ''?'col-70 ':'')}>
            <MultiClamp ellipsis="..." clamp={3}>
              <div dangerouslySetInnerHTML={{__html: item.description}}/>
            </MultiClamp>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SearchItem;
