import React, {useLayoutEffect, useState} from 'react';
import {connect} from "react-redux";
import DocumentTitle from 'react-document-title';
import {ToastContainer} from "react-toastify";

import ThemeContext from "./themeContext";
import Routes from './routes';
import FooterBing from "./components/bing/Footer";

// Custom Hook to get window height when browser is resized
function useWindowSize() {
  useLayoutEffect(() => {
    function updateSize() {
      let windowInnerHeight = window.innerHeight;
      document.documentElement.style.setProperty('--windowInnerHeight', `${windowInnerHeight}px`);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
}

function App(props) {
  useWindowSize();

  // Fake contents to load woff2 fonts at initial loading
  const fakeContents = () =>{
    return(
      <div className="fake-content">
        <span className="fw-300"/>
        <span className="fw-400"/>
        <span className="fw-500"/>
        <span className="fw-600"/>
        <span className="fw-700"/>
        <span className="fw-800"/>
        <span className="fw-900"/>
      </div>
    )
  };

  return (
    <DocumentTitle title={props.documentTitle}>
      <ThemeContext.Provider value={props.theme}>
        <div>
          {fakeContents()}
          <Routes/>
          {props.isFooterRequired &&
            <FooterBing/>
          }
          <ToastContainer />
        </div>
      </ThemeContext.Provider>
    </DocumentTitle>
  );
}

const stateToProps=(state)=>{
  return{
    documentTitle: state.common.documentTitle,
    theme: state.settings.theme,
    isFooterRequired: state.common.isFooterRequired
  }
};

export default connect(stateToProps,null)(App);
