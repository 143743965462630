import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import InfiniteScroll from 'react-infinite-scroller';
import TimeAgo from "react-timeago";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import englishStrings from "react-timeago/lib/language-strings/en";
import {callImageSearchApi} from "../../actions/search";
import {toast} from "react-toastify";


const formatter = buildFormatter(englishStrings);

function SearchImages(props) {
  const options = {
    resize: true,
    firstItemClass: 'first-item',
    lastRowClass: 'last-row',
    minMargin: 5,
    maxMargin: 15
  };

  const [galleryContainerWidth, setGalleryContainerWidth] = useState('auto');
  const [imageDisplayRows, setImageDisplayRows] = useState([]);
  const [isResizingWindow,setIsResizingWindow] = useState(false);
  const ref = useRef(null);

  useLayoutEffect(() => {
    function windowResizedImageGallery() {
      setIsResizingWindow(true);

      const {searchItemsImage} = props;
      let items = [];
      for (let i = 0; i < searchItemsImage.length; i++) {
        const img = searchItemsImage[i];

        items.push({
          imageUrl: img.imageUrl,
          imageBase64: img.imageBase64,
          width: img.imageWidth,
          height: img.imageHeight,
          classList: [],
          marginRight: '',
          isFirstItem: false,
          isLastRow: false,
          publishedTime: img.publishedTime,
          domainName: img.domainName,
          imageHasError: false
        })
      }

      DrawImageGallery(false,items);
      setTimeout(()=>{
        setIsResizingWindow(false);
      }, 100);
    }

    window.addEventListener('resize', ()=> windowResizedImageGallery);
    return () => window.removeEventListener('resize', windowResizedImageGallery);
  }, []);

  useEffect(() => {
    const {searchItemsImage} = props;
    let items = [];
    for (let i = 0; i < searchItemsImage.length; i++) {
      const img = searchItemsImage[i];

      items.push({
        imageUrl: img.imageUrl,
        imageBase64: img.imageBase64,
        width: img.imageWidth,
        height: img.imageHeight,
        classList: [],
        marginRight: '',
        isFirstItem: false,
        isLastRow: false,
        publishedTime: img.publishedTime,
        domainName: img.domainName,
        imageHasError: false
      })
    }

    if (props.imagesItemsChanged === 'first_new_data') {
      DrawImageGallery(true, items);
    } else {
      DrawImageGallery(false, items);
    }
  }, [props.searchItemsImage]);

  useEffect(()=>{
    if (!props.waitingImageSearch && !props.imageSearchSuccess && props.imageSearchError){
      toast.error('There have an error in server end! Error:' + props.imageSearchError,{
        position: toast.POSITION.TOP_RIGHT,
        draggable: true
      })
    }
  },[props.waitingImageSearch,props.imageSearchSuccess,props.imageSearchError]); // eslint-disable-line react-hooks/exhaustive-deps

  const onScrollDown = () => {
    if (!props.waitingImageSearch && !props.imageSearchAtEnd && !isResizingWindow) {
      props.callImageSearchApi(props.currentPageImage + 1);
    }
  };

  const DrawImageGallery = (isFirstCall, items) => {
    let container = ref.current;
    let rowWidth = 0;
    let rowElems = [];
    let itemsSize = items.length;
    let singleImagePerRow = false;
    let containerOffsetVal = 0;
    let rows = [];
    // read

    if (isFirstCall === true) {
      /// Ugly hack. Added 20px offset val to give space for scroll bar. Need to investigate and remove later
      containerOffsetVal = 20;
    }

    let containerStyle = getComputedStyle(container);
    let containerWidth = Math.floor(container.getBoundingClientRect().width) - parseFloat(containerStyle.getPropertyValue('padding-left')) - parseFloat(containerStyle.getPropertyValue('padding-right')) - containerOffsetVal;

    if (isFirstCall === true) {
      setGalleryContainerWidth(containerWidth + 'px');
    }

    let itemAttrs = [];

    for (let i = 0; i < itemsSize; ++i) {
      let w;
      let h;
      w = items[i].width;
      h = items[i].height;

      if (h > 210) {
        let theImageSizeRatio = w / h;
        w = parseInt((210 * theImageSizeRatio).toString());
        h = 210;
      }

      itemAttrs[i] = {
        width: w,
        height: h
      };
    }

    // write
    for (let index = 0; index < itemsSize; ++index) {
      // add element to row
      rowWidth += itemAttrs[index].width;
      items[index].width = itemAttrs[index].width;
      items[index].height = itemAttrs[index].height;
      rowElems.push(items[index]);

      // check whether width of row is too high
      if (rowWidth + options.maxMargin * (rowElems.length - 1) > containerWidth || singleImagePerRow) {
        let rowMargin;

        let diff = rowWidth + options.maxMargin * (rowElems.length - 1) - containerWidth;
        let nrOfElems = rowElems.length;

        // change margin
        let maxSave = (options.maxMargin - options.minMargin) * (nrOfElems - 1);
        if (maxSave < diff) {
          rowMargin = options.minMargin;
          diff -= (options.maxMargin - options.minMargin) * (nrOfElems - 1);
        } else {
          rowMargin = options.maxMargin - diff / (nrOfElems - 1);
          diff = 0;
        }

        let rowElem,
          newHeight = null,
          widthDiff = 0,
          newRowElems = [];
        for (let rowElemIndex = 0; rowElemIndex < rowElems.length; rowElemIndex++) {
          rowElem = rowElems[rowElemIndex];

          var rowElemWidth = itemAttrs[index + parseInt(rowElemIndex.toString()) - rowElems.length + 1].width;
          var newWidth = rowElemWidth - ((rowElemWidth / rowWidth) * diff);
          newHeight = Math.round(itemAttrs[index + parseInt(rowElemIndex.toString()) - rowElems.length + 1].height * (newWidth / rowElemWidth));

          if (widthDiff + 1 - newWidth % 1 >= 0.5) {
            widthDiff -= newWidth % 1;
            newWidth = Math.floor(newWidth);
          } else {
            widthDiff += 1 - newWidth % 1;
            newWidth = Math.ceil(newWidth);
          }

          rowElem.width = newWidth;
          rowElem.height = newHeight;

          if (rowElemIndex < rowElems.length - 1) {
            rowElems[rowElemIndex].marginRight = rowMargin;
          }

          if (rowElemIndex === 0) {
            rowElem.isFirstItem = true;
          }

          newRowElems.push(rowElem);
        }

        rows.push(newRowElems);

        rowElems = [];
        newRowElems = [];
        rowWidth = 0;
      } else if ((rowWidth + options.maxMargin * (rowElems.length - 1)) === containerWidth) {
        let rowElem;
        let newRowElems = [];

        for (let rowElemIndex = 0; rowElemIndex < rowElems.length; rowElemIndex++) {
          rowElem = rowElems[rowElemIndex];

          // if first element in row
          if (rowElemIndex === 0) {
            rowElem.isFirstItem = true;
          }

          rowElem.width = itemAttrs[index + parseInt(rowElemIndex.toString()) - rowElems.length + 1].width;
          rowElem.height = itemAttrs[index + parseInt(rowElemIndex.toString()) - rowElems.length + 1].height;


          if (rowElemIndex < rowElems.length - 1) {
            rowElem.marginRight = options.maxMargin.toString();
          }

          newRowElems.push(rowElem);
        }

        rows.push(newRowElems);
        rowElems = [];
        newRowElems = [];
        rowWidth = 0;
      }
    }
    setImageDisplayRows(rows);
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-100 mt-0 mt-sm-0">
          <div ref={ref} className="image-gallary-row" style={{width: `${galleryContainerWidth}`}}>
            <InfiniteScroll
              pageStart={0}
              threshold={0}
              loadMore={onScrollDown}
              hasMore={!props.waitingImageSearch && !props.imageSearchAtEnd}
              getScrollParent={() => ref}
            >
              {imageDisplayRows.map((rows, rowIndex) => {
                return (
                  <div key={rowIndex} className="row mt-3 mb-5 ml-1 mr-1 align-items-center">
                    {rows.map((item, itemIndex) => {
                      return (
                        <div
                          key={itemIndex}
                          className={'image-gallery-item' + (item.isFirstItem ? ' first-item' : '') + (item.isLastRow ? ' last-row' : '')}
                          style={{
                            width: item.width + 'px',
                            height: item.height + 'px',
                            marginRight: item.marginRight + 'px'
                          }}
                        >
                          <a href={item.imageUrl} target="_blank">
                            {item.imageBase64 !== '' &&
                            <img src={item.imageBase64} alt=""/>
                            }
                          </a>
                          {item.showFooter &&
                          <div className="image-footer">
                            <TimeAgo date={item.publishedTime} formatter={formatter}/>
                            &nbsp;
                            &nbsp;
                            {item.domainName}
                          </div>
                          }
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </InfiniteScroll>
          </div>
        </div>
      </div>
      {props.waitingImageSearch &&
        <i className="bubble-loader">
          <span/>
          <span/>
          <span/>
        </i>
      }
    </React.Fragment>
  )
}

const stateToProps=(state)=>{
  return{
    waitingImageSearch:state.search.waitingImageSearch,
    imageSearchSuccess:state.search.imageSearchSuccess,
    imageSearchError:state.search.imageSearchError,
    searchItemsImage:state.search.searchItemsImage,
    imagesItemsChanged:state.search.imagesItemsChanged,
    imageSearchAtEnd:state.search.imageSearchAtEnd,
    currentPageImage:state.search.currentPageImage
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    callImageSearchApi: (pageNumber) => dispatch(callImageSearchApi(pageNumber))
  }
};

export default connect(stateToProps,dispatchToProps)(SearchImages);
