export const WAITING_REQUEST = 'COMMON.WAITING_REQUEST';
export const SET_WINDOW_INNER_WIDTH = 'COMMON.SET_WINDOW_INNER_WIDTH';
export const SET_DOCUMENT_TITLE = 'COMMON.SET_DOCUMENT_TITLE';
export const IS_AT_MAIN = 'COMMON.IS_AT_MAIN';
export const SET_ACTIVE_TAB = 'COMMON.SET_ACTIVE_TAB';
export const SET_KEEP_AUTO_COMPLETE_OPENED = 'COMMON.SET_KEEP_AUTO_COMPLETE_OPENED';
export const SET_IS_FOOTER_REQUIRED = 'COMMON.SET_IS_FOOTER_REQUIRED';

export const CALL_PUBLIC_SEARCH_ENGINE_LIST = 'SETTINGS.CALL_PUBLIC_SEARCH_ENGINE_LIST';
export const PUBLIC_SEARCH_ENGINE_LIST_WAITING = 'SETTINGS.PUBLIC_SEARCH_ENGINE_LIST_WAITING';
export const PUBLIC_SEARCH_ENGINE_LIST_SUCCESS = 'SETTINGS.PUBLIC_SEARCH_ENGINE_LIST_SUCCESS';
export const PUBLIC_SEARCH_ENGINE_LIST_FAILED = 'SETTINGS.PUBLIC_SEARCH_ENGINE_LIST_FAILED';
export const SET_SELECTED_SEARCH_ENGINE = 'SETTINGS.SET_SELECTED_SEARCH_ENGINE';

export const SET_SEARCH_TEXT = 'SEARCH.SET_SEARCH_TEXT';
export const SET_SEARCH_TEXT_FOR_HIGHLIGHT = 'SEARCH.SET_SEARCH_TEXT_FOR_HIGHLIGHT';
export const SET_RELATED_SEARCH_TEXT = 'SEARCH.SET_RELATED_SEARCH_TEXT';
export const SET_CURRENT_PAGE = 'SEARCH.SET_CURRENT_PAGE';
export const SET_FLOATING_SEARCH_ICON_VISIBLE = 'SEARCH.SET_FLOATING_SEARCH_ICON_VISIBLE';

export const CLEAR_AUTO_COMPLETE_DATA = 'SEARCH.CLEAR_AUTO_COMPLETE_DATA';
export const AUTO_COMPLETE_CALL = 'SEARCH.AUTO_COMPLETE_CALL';
export const AUTO_COMPLETE_WAITING = 'SEARCH.AUTO_COMPLETE_WAITING';
export const AUTO_COMPLETE_SUCCESS = 'SEARCH.AUTO_COMPLETE_SUCCESS';
export const AUTO_COMPLETE_FAILED = 'SEARCH.AUTO_COMPLETE_FAILED';
export const SET_RUN_AUTOCOMPLETE = 'SEARCH.SET_RUN_AUTOCOMPLETE';

export const WEB_SEARCH_CALL = 'SEARCH.WEB_SEARCH_CALL';
export const NEWS_SEARCH_CALL = 'SEARCH.NEWS_SEARCH_CALL';
export const IMAGE_SEARCH_CALL = 'SEARCH.IMAGE_SEARCH_CALL';

export const CLEAR_DID_U_MEAN = 'SEARCH.CLEAR_DID_U_MEAN';
export const CLEAR_SEARCH_DATA = 'SEARCH.CLEAR_SEARCH_DATA';
export const CLEAR_NEWS_DATA = 'SEARCH.CLEAR_NEWS_DATA';

export const SEARCH_WAITING_REQUEST = 'SEARCH.SEARCH_WAITING_REQUEST';
export const SEARCH_SUCCESS = 'SEARCH.SEARCH_SUCCESS';
export const SEARCH_FAILED = 'SEARCH.SEARCH_FAILED';

export const NEWS_WAITING_REQUEST = 'SEARCH.NEWS_WAITING_REQUEST';
export const NEWS_SUCCESS = 'SEARCH.NEWS_SUCCESS';
export const NEWS_FAILED = 'SEARCH.NEWS_FAILED';

export const SET_RELATED_SEARCH_TO_SEARCH_TEXT = 'SEARCH.SET_RELATED_SEARCH_TO_SEARCH_TEXT';
export const CHANGE_RELATED_SEARCH_TEXT = 'SEARCH.CHANGE_RELATED_SEARCH_TEXT';

export const SET_WINDOW_RESIZE_DATE = 'SEARCH.SET_WINDOW_RESIZE_DATE';
export const CLEAR_IMAGES_DATA = 'SEARCH.CLEAR_IMAGES_DATA';
export const IMAGES_WAITING_REQUEST = 'SEARCH.IMAGES_WAITING_REQUEST';
export const IMAGES_SUCCESS = 'SEARCH.IMAGES_SUCCESS';
export const IMAGES_FAILED = 'SEARCH.IMAGES_FAILED';
export const IGNORE_IMAGE_GALLERY_API_CALL = 'SEARCH.IGNORE_IMAGE_GALLERY_API_CALL'
