import {put} from "redux-saga/effects";

export function handleServerSuccess(data, dispatch, waitingConstantName, constantName) {
  dispatch({ type: constantName, value: data });
  dispatch({ type: waitingConstantName, value: false });
}

export function handleServerError (err, dispatch,waitingConstantName, constantName) {
  if (typeof err.response === "undefined") {
    dispatch({ type: constantName, value: "Network error!" });
  } else {
    if (err.response.status === 500) {
      dispatch({
        type: constantName,
        value: "Internal server error!"
      });
    } else {
      dispatch({ type: constantName, value: err.response.data });
    }
  }

  dispatch({ type: waitingConstantName, value: false });
}

export function* handleApiSuccess(data, waitingConstantName, constantName) {
  yield put({ type: constantName, value: data });
  yield put({type: waitingConstantName, value: false});
}

export function* handleApiError (err,waitingConstantName, constantName) {
  if (typeof err.response === "undefined") {
    yield put({ type: constantName, value: "Network error!" });
  } else {
    if (err.response.status === 500) {
      yield put({ type: constantName, value: "Internal server error!" });
    } else {
      yield put({ type: constantName, value: err.response.data });
    }
  }

  yield put({ type: waitingConstantName, value: false });
}
