import React, {useEffect, useState, useRef, useContext, useLayoutEffect} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {isMobileOnly} from "react-device-detect";

import Autocomplete from "../../../libs/auto-complete/Autocomplete";
import iconClear from "../../../assets/images/icon_clear.svg";
import {
  IS_AT_MAIN,
  SET_SEARCH_TEXT,
  SET_KEEP_AUTO_COMPLETE_OPENED,
  AUTO_COMPLETE_CALL,
  CLEAR_IMAGES_DATA,
  CLEAR_AUTO_COMPLETE_DATA,
  SET_DOCUMENT_TITLE,
  SET_SEARCH_TEXT_FOR_HIGHLIGHT,
  SET_RUN_AUTOCOMPLETE
} from "../../../constants/actionTypes";
import ThemeContext from "../../../themeContext";
import HighlighterText from "../common/HighlighterText";

function SearchConsole(props) {
  const [searchText, setSearchText] = useState(props.searchTextStore);
  const [autoCompleteItems, setAutoCompleteItems] = useState([]);
  // Bellow two states are tricks to tackle not to re-call autoComplete api immediately
  // when user press keyup or down on auto-complete menu items
  const [searchTextChangeTime, setSearchTextChangeTime] = useState(null);
  const [reRunAutoCompleteRequired, setReRunAutoCompleteRequired] = useState(false);
  const [showClearButton,setShowClearButton] = useState(false);
  const [isActive, setIsActive] = useState(!props.isSettingsPage);
  const autoCompleteRef = useRef(null);
  const theme = useContext(ThemeContext);

  let autoCompleteMenuLeft = '-2px';
  let autoCompleteMenuWidth = 'calc(100% + 3px)';
  if (isMobileOnly){
    autoCompleteMenuLeft = '-1px';
    autoCompleteMenuWidth = 'calc(100% + 2px)';
  }

  useLayoutEffect(()=>{
    function windowBlur (){
      props.setRunAutocomplete(false);
    }

    window.addEventListener("blur",windowBlur);
    return ()=>{
      window.removeEventListener("blur",windowBlur)
    }
  },[])

  useEffect(() => {
    function handleClickOutside(event) {
      if (autoCompleteRef.current.refs.input && !autoCompleteRef.current.refs.input.contains(event.target)) {
        setIsActive(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [autoCompleteRef]);

  useEffect(()=>{
    if (props.runAutocomplete){
      props.setSearchTextForHighlight(searchText);
      setSearchTextChangeTime(new Date());
      setReRunAutoCompleteRequired(false);
    }
  },[props.runAutocomplete]);

  useEffect(() => {
    setSearchText(props.searchTextStore);
  }, [props.searchTextStore]);

  useEffect(() => {
    if (!searchText) {
      setAutoCompleteItems([]);
      props.clearAutoCompleteData();
    } else {
      getAutocomplete();
    }
  }, [searchTextChangeTime]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAutoCompleteItems(props.autoComplete);
  }, [props.autoComplete]);

  const handleSubmit = (event) => {
    event.preventDefault();
    callSearch(searchText);
  };

  const handleKeyDownAutocomplete = (event) => {
    if (event && event.keyCode === 13) {
      callSearch(searchText);
      event.keyCode = 27;
    }
  };

  const handleFocus = () => {
    if (reRunAutoCompleteRequired && props.runAutocomplete) {
      props.setSearchTextForHighlight(searchText);
      setSearchTextChangeTime(new Date());
      setReRunAutoCompleteRequired(false);
    }

    if (isMobileOnly){
      setShowClearButton(true);
      props.history.push('/search_console');
    }
  };

  const handleBlur = () =>{
    if (isMobileOnly && props.isAtMain){
      setShowClearButton(false);
    }
  }

  const onKeyDownHandler = (index) => {
    setSearchText(autoCompleteItems[index]);
    props.setSearchTextStore(autoCompleteItems[index]);
    setReRunAutoCompleteRequired(true);
  };

  const onKeyUpHandler = (index) => {
    setSearchText(autoCompleteItems[index]);
    props.setSearchTextStore(autoCompleteItems[index]);
    setReRunAutoCompleteRequired(true);
  };

  const handleChangeAutocomplete = (event) => {
    if (!(event && event.target)) return false;
    const value = event.target.value;

    if (props.keepAutoCompleteOpened) {
      props.setIsBrowserRefreshed(false);
    }

    if (value) {
      setSearchText(event.target.value);
      props.setSearchTextForHighlight(event.target.value);
      props.setSearchTextStore(event.target.value);
      setSearchTextChangeTime(new Date());
    } else {
      setSearchText(value);
      props.setSearchTextStore(value);
      setAutoCompleteItems([]);
      props.clearAutoCompleteData();
    }
  };

  const selectAutocomplete = (value) => {
    setSearchText(value);
    props.setSearchTextStore(value);
    callSearch(value);
  };

  const getAutocomplete = () => {
    if (!searchText) return false;
    props.callAutoCompleteApi(searchText);
  };

  const callSearch = (text) => {
    props.setDocumentTitle(text);
    props.setSearchTextForHighlight(text);

    if (props.isAtMain) {
      props.setIsAtMainTrue();
      props.setIsBrowserRefreshed(true);
      props.history.push(`/search/${text}/all`);
    } else {
      if (props.activeTab === 'all') {
        props.history.push(`/search/${text}/all`);
      } else if (props.activeTab === 'news') {
        props.history.push(`/search/${text}/news`);
      } else if (props.activeTab === 'images') {
        props.clearImageData();
        props.history.push(`/search/${text}/images`);
      }
    }
  };

  const autoCompleteOnClick = () => {
    if (!props.runAutocomplete){
      props.setRunAutocomplete(true);
    }

    setIsActive(true);
    props.setIsBrowserRefreshed(false);
  };

  const clearSearchInput = (e) => {
    e.preventDefault();
    autoCompleteRef.current.refs.input.focus();
    setSearchText('');
    props.setSearchTextStore('');
    setAutoCompleteItems([]);
    props.clearAutoCompleteData();
  };

  const onMouseEnter = () =>{
    setShowClearButton(true);
  };

  const onMouseLeave = () =>{
    setShowClearButton(false);
  };

  return (
    <div className={'console' + (isActive || isMobileOnly ? ' active' : '')}
     onMouseEnter={onMouseEnter}
     onMouseLeave={onMouseLeave}
    >
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <Autocomplete
            ref={autoCompleteRef}
            getItemValue={item => item}
            items={props.autoComplete}
            autoHighlight={false}
            renderInput={(renderInputProps)=>{
              return(
                <input
                  type="text"
                  placeholder=""
                  className={'input-search'}
                  autoFocus={!props.isSettingsPage && !isMobileOnly}
                  {...renderInputProps}
                />
              )
            }}
            renderItem={(item, isHighlighted) =>
              <div key={item}
                   style={{
                     background: isHighlighted ? '#f2f2f2' : 'white',
                     paddingLeft: '20px',
                     height: '30px',
                     paddingTop: '3px',
                     cursor: 'pointer',
                     fontSize: '1rem'
                   }}
              >
                <HighlighterText
                  searchWords={props.searchTextForHighlight}
                  textToHighlight={item}
                />
              </div>
            }
            renderMenu={(items, value, styles) => {
              if (searchText && !props.keepAutoCompleteOpened && props.autoComplete.length>0) {
                return (
                  <div
                    style={{
                      ...styles,
                      zIndex: '1',
                      position: 'absolute',
                      top: '32px',
                      left: autoCompleteMenuLeft,
                      border: '1px solid rgba(0,0,0,.15)',
                      width: autoCompleteMenuWidth
                    }}
                    children={items}>
                  </div>
                );
              } else {
                return <div/>;
              }
            }
            }
            inputProps={{
              onKeyDown: handleKeyDownAutocomplete,
              onFocus: handleFocus,
              onBlur: handleBlur,
              onClick: autoCompleteOnClick
            }}
            onChange={handleChangeAutocomplete}
            onSelect={selectAutocomplete}
            value={searchText}
            onKeyDownHandler={onKeyDownHandler}
            onKeyUpHandler={onKeyUpHandler}
          />

          <a href="/" className="btn-clear" onClick={(event) => clearSearchInput(event)}>
            {searchText && showClearButton &&
              <img src={iconClear} alt={''}/>
            }
          </a>

          <button className="btn-search" type="submit" disabled={!searchText} style={{color: theme.primaryColor}}>
            <FontAwesomeIcon icon={faSearch}/>
          </button>
        </div>
      </form>
    </div>
  )
}

const stateToProps=(state)=>{
  return{
    activeTab: state.common.activeTab,
    autoComplete:state.search.autoComplete,
    isAtMain: state.common.isAtMain,
    searchTextStore: state.search.searchText,
    searchTextForHighlight: state.search.searchTextForHighlight,
    keepAutoCompleteOpened: state.common.keepAutoCompleteOpened,
    runAutocomplete: state.search.runAutocomplete
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    setSearchTextStore: (searchText) => dispatch({type:SET_SEARCH_TEXT,value:searchText}),
    setSearchTextForHighlight: (searchText) => dispatch({type:SET_SEARCH_TEXT_FOR_HIGHLIGHT,value:searchText}),
    clearImageData: () => dispatch({type:CLEAR_IMAGES_DATA}),
    callAutoCompleteApi: (text) => dispatch({ type: AUTO_COMPLETE_CALL, value: text }),
    setIsAtMainTrue: ()=>dispatch({type:IS_AT_MAIN,value:true}),
    setIsBrowserRefreshed: (val) => dispatch({type:SET_KEEP_AUTO_COMPLETE_OPENED, value: val}),
    clearAutoCompleteData: () => dispatch({type:CLEAR_AUTO_COMPLETE_DATA}),
    setDocumentTitle: (val) => dispatch({type:SET_DOCUMENT_TITLE,value:val}),
    setRunAutocomplete: (val) => dispatch({type:SET_RUN_AUTOCOMPLETE,value:val})
  }
};

export default withRouter(connect(stateToProps,dispatchToProps)(SearchConsole));
